<template>
  <main class="mt-3">
  <div class="pl-1">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;단 일 상 품 정 보&nbsp;</u></h2>
        <div class="text-center fs-5 text-danger fw-bold" v-if="productInfo.assoFlag != '' && productInfo.assoFlag != undefined && productInfo.assoFlag != null">
          [ 연계상품 ]
          <p class="fs-6 text-dark fw-normal">(멤버십이나 기타 할인 등이 적용되지 않습니다)</p>
        </div>
          <div class="text-success text-start fs-6 mb-2">
            <b>[기본정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" 
              @click="dataBaseTransaction('U','M');">&nbsp;UPDATE</i>&nbsp; ]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="col-md-2 table-secondary">상품코드</th>
                <td class="col-md-4 text-primary">
                  <div class="flex-pdtCode">
                    <div>
                    {{this.productId}}
                    </div>
                    <div style="color:black;" class="flex-input">
                      <i class="bi bi-link fw-bold text-primary icon"></i>연계상품화&nbsp;&nbsp;
                      <span>
                        <div class="input-group">
                          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_assoCode" v-model="productInfo.assoFlag">
                            <option value="noVal" class="text-danger">연계취소</option>
                            <option :value="ac.assoCode" :key=i v-for="(ac,i) in assCodeList">{{ac.assoName}} ({{ ac.assoCode }}/{{ ac.linkStyle }})</option>
                          </select>
                          <button type="button" class="btn btn-sm btn-info" @click="setAssoProduct(productInfo.assoFlag);">변경</button>
                        </div>
                      </span>
                    </div>
                    <div style="color:black;" class="flex-input">
                      <span>
                        <div class="input-group">
                          <button type="button" class="btn btn-sm btn-outline-success" @click="previewAssoPdt(productInfo.assoFlag);">미리보기</button>
                        </div>
                      </span>
                    </div>
                  </div>
                </td>
                <th class="col-md-2 table-secondary">상품(속성)</th>
                <td class="col-md-4">
                  <div class="form-check-inline small" :key=i v-for="(baseCode,i) in this.sRoute">
                    <div v-if="this.sRoute[i].baseCode === this.salesRoute">
                      <label class="form-check-label text-danger" role="switch" for="flexRadioDefault1">
                        <b>{{ this.sRoute[i].codeNameKor }}</b>
                      </label>
                    </div>
                    <div v-else>
                      <label class="form-check-label text-secondary" for="flexRadioDefault1">
                        {{ this.sRoute[i].codeNameKor }}
                      </label>
                    </div>
                  </div>
                  &nbsp;&nbsp;(
                  <span v-if="productInfo.tripProp === 'F'" class="text-info"><b><i>{{productInfo.tripProp}}</i></b></span>
                  <span v-if="productInfo.tripProp === 'D'" class="text-warning"><b><i>{{productInfo.tripProp}}</i></b></span>
                  )
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>상품명(국문)</th>
                <td class="col-md-4"><input type="text" class="form-control text-small" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor"></td>
                <th scope="row" class="table-secondary col-md-1">상품명(영문)</th>
                <td class="col-md-4"><input type="text" class="form-control text-small" ref="txt_pdtNameKor" v-model="productInfo.pdtNameEng"></td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>출발/여행국</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_ctCd" v-model="productInfo.ctCd">
                      <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd" v-model="productInfo.ntCd" 
                      @change="changeNationList()">
                      <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
                    </select>
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>지역/체류일</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="productInfo.arCd">
                      <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
                    </select>
                    <input type="number" min=0 class="form-control text-end text-small" ref="txt_stayCnt" v-model="productInfo.stayCnt">
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>게시기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_tripStart" v-model="productInfo.tripStart">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_tripEnd" v-model="productInfo.tripEnd">
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>판매기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_salesEnd" v-model="productInfo.salesEnd">
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">
                  매니저/판매처
                  <div class="text-danger">※ 멤버십상품은 엔데이트립으로만 가능</div>
                </th>
                <td class="col-md-4">
                  <div class="input-group">
                    <div class="input-group">
                      <!-- <span class="input-group-text text-primary">平<input class="form-check-input" type="checkbox" v-model="productInfo.sFlag_g" true-value="Y" false-value="N" checked disabled></span> -->
                      <!-- <input type="number" class="form-control text-end" ref="txt_unitPriceG" v-model="productInfo.nominalAmt" disabled> -->
                      <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ctCd" v-model="productInfo.manId">
                      <option :value="m.handlerId" :key=i v-for="(m,i) in this.managerInfo">{{ m.handlerNameKor }} ({{ m.handlerId }})</option>
                    </select>                      

                      <span class="input-group-text text-success">Ntabi&nbsp;<input class="form-check-input" type="checkbox" v-model="productInfo.sellerCode_nt" true-value="Y" false-value="N"></span>

                      <span class="input-group-text text-primary">Ndaytrip&nbsp;<input class="form-check-input" type="checkbox" v-model="productInfo.sellerCode_nd" true-value="Y" false-value="N"></span>
                    </div>
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1">
                  멤버십 <span style="color:red; font-size:small;">(현지투어용전용 | 엔데어와 버라이어티팩X)</span>
                  <div style="font-size:small;"><u>멤버십 제외 시</u>, 동반할인을 0으로 세팅 후, [동반할인]버튼을 클릭하세요</div>
                  <div>
                    <button type="button" class="btn btn-sm btn-primary mt-1" style="font-size:smaller" @click="goToMembershipSetPage(this.productId);">
                      환급설정
                    </button>
                  </div>
                </th>
                <td class="col-md-4" v-if="this.productInfo.salesRoute == 'L' && (this.productInfo.nrFlag != 'Y' && this.productInfo.vrFlag != 'Y')">
                  <div class="input-group">
                    <div class="input-group">
                      <span class="input-group-text" style="color:darkgoldenrod;font-size:small">PRM&nbsp;
                        <input class="form-check-input" type="checkbox" v-model="productInfo.memLevel_P" true-value="Y" false-value="N" @change="changeNoRefund('pre', productInfo.memLevel_P)">&nbsp;&nbsp;
                      </span>
                      <input type="number" class="form-control text-end" min="0" max="100" style="font-size:small" v-model="productInfo.dcRateForPre">
                      <span class="input-group-text" style="font-size: small; display:none">% 환급</span>

                      <input type="number" class="form-control text-end" min="0" max="100" v-model="productInfo.dcForStd" style="width:5%;font-size: small;">
                      <span class="input-group-text" style="font-size: small;width:18%">% 할인(STD用)</span>&nbsp;&nbsp;&nbsp;

                      <span class="input-group-text" style="color:royalblue;font-size:small">STD&nbsp;
                        <input class="form-check-input" type="checkbox" v-model="productInfo.memLevel_S" true-value="Y" false-value="N"  @change="changeNoRefund('std', productInfo.memLevel_S)">
                      </span>
                      <input type="number" class="form-control text-end" min="0" max="100" style="font-size:small" v-model="productInfo.dcRateForStd">
                      <span class="input-group-text" style="font-size:small">% 환급</span>
                      &nbsp;&nbsp;
                      <button type="button" class="btn btn-sm btn-outline-danger" @click="setMembershipDC();">환급생성</button>
                    </div>
                    <!-- 동반자할인 -->
                    <div class="input-group mt-1">
                      <span class="input-group-text" style="color:darkgoldenrod;font-size:small">PRM&nbsp;
                      </span>
                      <input type="number" class="form-control text-end" min="0" max="100" style="font-size:small" v-model="productInfo.companionDC_P">
                      <span class="input-group-text" style="font-size: small;">% 할인</span>
                      <span class="input-group-text" style="color:royalblue;font-size:small">STD&nbsp;
                      </span>
                      <input type="number" class="form-control text-end" min="0" max="100" style="font-size:small" v-model="productInfo.companionDC_S">
                      <span class="input-group-text" style="font-size:small">% 할인</span>
                      &nbsp;&nbsp;
                      <button type="button" class="btn btn-sm btn-success" @click="setCompanionDC();">동반할인</button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">체크포인트</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" min=0 class="form-control text-end text-small w-25" ref="txt_lookUp" value="템플릿" readonly>
                    <select class="form-select form-select-sm w-75" aria-label=".form-select-sm" ref="sel_lookUp" v-model="patternCode_L" @change = "set_supplement('L')">
                      <option :value="l.patternCode" :key=j v-for="(l,j) in patternList_L">{{l.pTitle}}</option>
                    </select>
                  </div>
                  <textarea class="form-control" placeholder="1,000자 이내" ref="txt_supLookup" id="floatingTextarea" style="height:150px" v-model="productInfo.supLookup" maxlength="1000"></textarea>
                </td>
                <!-- <th scope="row" class="table-secondary col-md-1">키워드</th>
                <td class="col-md-4"><input type="text" class="form-control" v-model="productInfo.pdtTopic"></td> -->
                <th scope="row" class="table-secondary col-md-1">
                  <p v-if="this.productInfo.salesRoute == 'T' || this.productInfo.salesRoute == 'F' || (this.productInfo.salesRoute == 'L' && (this.productInfo.nrFlag == 'Y' || this.productInfo.vrFlag == 'Y'))">멤버십할인</p>
                  <p>포인트</p>
                  <!-- <p>부가세</p> -->
                </th>
                <td class="col-md-4">
                  <!-- <div class="input-group" v-if="this.productInfo.salesRoute != 'L'"> -->
                  <div class="input-group" v-if="this.productInfo.salesRoute == 'T' || this.productInfo.salesRoute == 'F' || (this.productInfo.salesRoute == 'L' && (this.productInfo.nrFlag == 'Y' || this.productInfo.vrFlag == 'Y'))">
                    <span class="input-group-text" style="color:darkgoldenrod">PRE</span>
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="productInfo.dcForMem_P">
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.dcRateForMem_P">
                      <option value="A" id="dcRateForMem_P">원</option>
                      <option value="P" id="dcRateForMem_P">%</option>
                    </select>
                    &nbsp;&nbsp;
                    <span class="input-group-text" style="color:slategray">STD</span>
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="productInfo.dcForMem_S">
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.dcRateForMem_S">
                      <option value="A" id="dcRateForMem_S">원</option>
                      <option value="P" id="dcRateForMem_S">%</option>
                    </select>
                  </div>
                  <div class="input-group mt-1">
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="productInfo.userPoint">
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.userPointMethod">
                      <option value="A" id="userPointMethod" selected>점</option>
                      <option value="P" id="userPointMethod">%</option>
                    </select>
                  </div>
                  <!-- <div class="input-group mt-1">
                    <input type="text" class="form-control text-end" ref="txt_vatPrice" v-model="productInfo.vatPrice">
                    <span class="input-group-text">%</span>
                  </div> -->
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">출발불가일</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="(예시) 2022-12-25, 2022-12-26, 매주 화요일" ref="txt_unableDpDate" id="floatingTextarea" style="height:110px" 
                    v-model="productInfo.unableDpDate" maxlength="200"></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">한줄소개</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:110px" 
                    v-model="productInfo.pdtRemarks" maxlength="200"></textarea>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="1000자 이내" ref="txt_inContent" id="floatingTextarea" style="height:110px" v-model="productInfo.inContent" maxlength="1000"></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">불포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="1000자 이내" ref="txt_exContent" id="floatingTextarea" style="height:110px" v-model="productInfo.exContent" maxlength="1000"></textarea>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">안내사항</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" min=0 class="form-control text-end text-small w-25" ref="txt_stayCnt" value="템플릿" readonly>
                    <select class="form-select form-select-sm w-75" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="patternCode_P" @change = "set_supplement('P')">
                      <option :value="p.patternCode" :key=j v-for="(p,j) in patternList_P">{{p.pTitle}}</option>
                    </select>
                  </div>
                  <textarea class="form-control" placeholder="5,000자 이내" ref="txt_inContent" id="floatingTextarea" style="height:150px" v-model="productInfo.supPrecaution" maxlength="5200"></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">취소안내</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" min=0 class="form-control text-end text-small w-25" ref="txt_stayCnt" value="템플릿" readonly>
                    <select class="form-select form-select-sm w-75" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="patternCode_C" @change = "set_supplement('C')">
                      <option :value="c.patternCode" :key=j v-for="(c,j) in patternList_C">{{c.pTitle}}</option>
                    </select>
                  </div>
                  <textarea class="form-control" placeholder="1000자 이내" ref="txt_exContent" id="floatingTextarea" style="height:150px" v-model="productInfo.supCancellation" maxlength="1000"></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[교통정보&nbsp; <i class="mb-2 bi bi bi-caret-down-fill text-end text-primary" style="cursor:pointer" @click="showArea('T');">&nbsp;EDIT</i>&nbsp; ]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">출발&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  한국({{this.pdtTransInfo1.ddCodeName}}) → {{this.productInfo.ntName}}({{this.pdtTransInfo1.daCodeName}})
                </td>
                <th class="table-secondary col-md-2">리턴&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  {{this.productInfo.ntName}}({{this.pdtTransInfo1.rdCodeName}}) → 한국({{this.pdtTransInfo1.raCodeName}})
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">출발편</th>
                <td class="col-md-4">
                  {{this.pdtTransInfo1.dmobileCodeName}}
                  <small>
                    ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                  </small>
                </td>
                <th scope="row" class="table-secondary col-md-1">리턴편</th>
                <td class="col-md-4">
                  {{this.pdtTransInfo1.rmobileCodeName}}
                  <small>
                    ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                  </small>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo1.transRemarks_dp" maxlength="300" readonly></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo1.transRemarks_ar" maxlength="300" readonly></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 교통정보 업데이트 -->
          <table class="table table-bordered border-success align-middle w-100" v-if="this.areaFlag === 'T'">
            <i class="bi bi-capslock-fill text-danger fs-4" style="cursor:pointer" @click="dataBaseTransaction('U', 'T');"></i>&nbsp;&nbsp;[UPDATE]
            <div class="row border p-sm-2 border-info">
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">출발정보<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd1" @change="changeDpTransList()">
                      <option :value="noVal">비이용</option>
                      <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd1"  @change="changeDpMobilityList()">
                      <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpdCd">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_dpd[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpd">{{termList_dpd[p].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpaCd" 
                      @change="getEtcFeeInfo('D',pdtTransInfo.trCd1,pdtTransInfo.mbCd1,pdtTransInfo.dpdCd)">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_dpa[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpa">{{termList_dpa[p].codeNameKor}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">교통편정보<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class= "input-group">
                    <input type="text" class="form-control text-end" ref="txt_dFlt" maxlength="4" v-model="pdtTransInfo.dpTpCode">
                    <input type="time" class="form-control" ref="txt_d_dpTime" v-model="pdtTransInfo.d_dpTime">
                    <input type="time" class="form-control" ref="txt_d_arTime" v-model="pdtTransInfo.d_arTime">
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">추가정보</label>
                <div class="col-md-9">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo.transRemarks_dp" maxlength="210"></textarea>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">유류할증료<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_dp" v-model="pdtTransInfo.oilCd_dp">
                      <option :value="noVal">비이용</option>
                      <option :value="oilSurcharge_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in oilSurcharge_dp">
                        {{oilSurcharge_dp[k].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_dp[k].ageFee)}}<small>{{oilSurcharge_dp[k].codeNameKor}}</small>)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">터미널이용료<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_dp" 
                      @change="changeTermFeeNameList('D', pdtTransInfo.termCd_dp)">
                      <option :value="noVal">비이용</option>
                      <option :value="termFeeNameList_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_dp">
                        {{termFeeNameList_dp[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_dp[k].tmCd}})
                      </option>
                    </select>
                    <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_dp">
                      <span class="input-group-text">{{termFee_dp[j].agegroup}}</span>
                      <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_dp[j].ageFee" readonly>
                      <span class="input-group-text">{{termFee_dp[j].codeNameKor}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row border p-sm-2 border-warning">
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">리턴정보</label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd2" @change="changeRtTransList">
                      <option :value="noVal">비이용</option>
                      <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd2"  @change="changeRtMobilityList">
                      <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtdCd">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_rtd[s].baseCode" :key=s v-for="(baseCode,s) in termList_rtd">{{termList_rtd[s].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtaCd"
                      @change="getEtcFeeInfo('R',pdtTransInfo.trCd2,pdtTransInfo.mbCd2,pdtTransInfo.rtdCd)">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_rta[s].baseCode" :key=s v-for="(baseCode,s) in termList_rta">{{termList_rta[s].codeNameKor}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">교통편정보<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class= "input-group">
                    <input type="text" class="form-control text-end" ref="txt_rFlt" maxlength="4" v-model="pdtTransInfo.rtTpCode">
                    <input type="time" class="form-control" ref="txt_r_dpTime" v-model="pdtTransInfo.r_dpTime">
                    <input type="time" class="form-control" ref="txt_r_arTime" v-model="pdtTransInfo.r_arTime">
                  </div>
                </div>
              </div>          
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">추가정보</label>
                <div class="col-md-9 mb-2">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo.transRemarks_ar" maxlength="210"></textarea>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">유류할증료<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_rt" v-model="pdtTransInfo.oilCd_rt">
                      <option :value="noVal">비이용</option>
                      <option :value="oilSurcharge_rt[j].etcFeeCode" :key=j v-for="(etcFeeCode,j) in oilSurcharge_rt">
                        {{oilSurcharge_rt[j].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_rt[j].ageFee)}}<small>{{oilSurcharge_rt[j].codeNameKor}}</small>)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">터미널이용료<span class="text-danger">*</span></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_rt" 
                      @change="changeTermFeeNameList('R', pdtTransInfo.termCd_rt)">
                      <option :value="noVal">비이용</option>
                      <option :value="termFeeNameList_rt[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_rt">
                        {{termFeeNameList_rt[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_rt[k].tmCd}})
                      </option>
                    </select>
                    <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_rt">
                      <span class="input-group-text">{{termFee_rt[j].agegroup}}</span>
                      <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_rt[j].ageFee" readonly>
                      <span class="input-group-text">{{termFee_rt[j].codeNameKor}}</span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[가격정보&nbsp;
              <!-- <i class="mb-2 bi bi bi-caret-down-fill text-end text-primary" style="cursor:pointer" @click="showArea('P');">&nbsp;EDIT</i>&nbsp;  -->
              ]
            </b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-1" colspan="6">
                  <button type="button" class="btn btn-sm btn-success" @click="goToPriceSetPage(this.productId);">상품가격 관리페이지<small>(기본가격 등록우선)</small></button>
                  &nbsp;&nbsp;&nbsp;
                  <!-- <button type="button" class="btn btn-sm btn-info" @click="goToOptionSetPage(this.productId);">옵션가격 관리페이지<small>(기본가격 등록우선)</small></button> -->
                </th>
              </tr>
              <tr>
                <th class="table-secondary col-md-5 text-center" colspan="3">1차결제&nbsp;&nbsp;
                  <button class="btn btn-sm btn-primary" @click="updateFirstPayInfo(this.productId)">변경</button>
                  <p class="text-danger">(잔여액은 2차 결제액으로 설정됩니다. 100%로 입력하면 전액결제로 처리됩니다)</p>
                </th>
                <td class="col-md-6 text-start small" colspan="3">
                  <div class="input-group mt-1">
                  <input type="number" class="form-control text-end" min="0" ref="firstPayment" style="width:70%;" v-model="productInfo.firstPayment">
                  <select class="form-select form-select-md" aria-label=".form-select-md" style="width:30%;" v-model="productInfo.paymentMethod">
                    <!-- <option value="A" id="paymentMethod" selected>원</option> -->
                    <option value="P" id="paymentMethod" selected>%</option>
                  </select>
                </div>
                </td>
              </tr>
              <!-- <tr>
                <th class="table-secondary col-md-1">프로모션</th>
                <td class="col-md-3 text-start small">
                  - {{this.pdtPromoInfo.promoName}}
                  <br>
                  - {{this.pdtPromoInfo.promoStart}} ~ {{this.pdtPromoInfo.promoEnd}}
                  <br>
                  - <span class="text-danger">{{getCurrencyFormat(this.pdtPromoInfo.promoRate)}}</span><small>({{this.pdtPromoInfo.pMethod}})</small>
                </td>
                <th class="table-secondary col-md-1">터미널이용료</th>
                <td class="col-md-3 text-start small">
                  - &nbsp;출발&nbsp;<br>
                  &nbsp;&nbsp;{{this.dTerm}}&nbsp;(
                  <span class="text-secondary" :key=i v-for=" (agegroup,i) in this.pdtTermFeeInfo_dp">
                    &nbsp;{{getCurrencyFormat(this.pdtTermFeeInfo_dp[i].ageFee)}}/</span>){{this.dCur}}
                  <br>
                  - &nbsp;리턴&nbsp;<br>
                  &nbsp;&nbsp;{{this.rTerm}}&nbsp;(
                  <span class="text-secondary" :key=i v-for=" (agegroup,i) in this.pdtTermFeeInfo_rt">
                    &nbsp;{{getCurrencyFormat(this.pdtTermFeeInfo_rt[i].ageFee)}}/</span>){{this.rCur}}
                    -- 교통정보 추가사항 참조
                </td>
                <th class="table-secondary col-md-1">유류할증료</th>
                <td class="col-md-4 text-start small">
                  - 출발편&nbsp;
                    ({{this.pdtOilSCInfo1.dMobility}} / {{getCurrencyFormat(this.pdtOilSCInfo1.dOil)}}{{this.pdtOilSCInfo1.dCur}})
                  <br>
                  - 리턴편&nbsp;
                    ({{this.pdtOilSCInfo1.rMobility}} / {{getCurrencyFormat(this.pdtOilSCInfo1.rOil)}}{{this.pdtOilSCInfo1.rCur}})
                    -- 교통정보 추가사항 참조
                </td>
              </tr> -->
            </tbody>
          </table>
          <table class="table table-bordered border-success align-middle w-100 h-auto" v-if="this.areaFlag === 'P'">
            <i class="bi bi-capslock-fill text-danger fs-4" style="cursor:pointer" @click="dataBaseTransaction(promoWork,'P');"></i>&nbsp;&nbsp;[Promotion UPDATE]
            <div class="row border p-sm-2 border-info">
              <div class="mb-3 row">
                <label class="col-md-3 col-form-label">방식선택</label>
                <div class="col-md-9">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="promoWork" value="C" name="rbt_promoWork">
                    <label class="form-check-label text-primary" for="promoWork1">신규<small>(적용기준: 최신생성일)</small></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="promoWork" value="U" name="rbt_promoWork">
                    <label class="form-check-label text-success" for="promoWork2">변경<small>(기존 프로모션 중 택1)</small></label>
                  </div>
                </div>
              </div>
              <!-- 프로모션 신규생성 -->
              <div class="border p-2" v-if="promoWork === 'C'">
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">프로모션명</label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" v-model="this.productPromotion.promoName">
                    </div>
                  </div>
                  <label class="col-md-3 col-form-label">할인<span class="text-secondary" style="font-size: small;">(원/%)</span></label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <input type="number" class="form-control text-end" v-model="productPromotion.promoRate">
                      <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productPromotion.promoMethod">
                        <option value="A" id="promoMethod" selected>원</option>
                        <option value="P" id="promoMethod">%</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">프로모션기간</label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">개시</span>
                      <input type="date" class="form-control" ref="txt_salesStart" v-model="productPromotion.promoStart">
                      <!-- <date-picker class="form-control" v-model="productPromotion.promoStart" type="date" lang="en" format="YYYY-MM-DD"></date-picker> -->
                      <span class="input-group-text" id="basic-addon1">종료</span>
                      <input type="date" class="form-control" ref="txt_salesStart" v-model="productPromotion.promoEnd">
                      <!-- <date-picker class="form-control" v-model="productPromotion.promoEnd" type="date" lang="en" format="YYYY-MM-DD"></date-picker> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- 프로모션 변경 택1 -->
              <div class="border p-2" v-else-if="promoWork === 'U'">
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">적용프로모션<span class="text-danger" style="font-size: small;">(0:실행)</span></label>
                  <div class="col-md-9">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_pdtPromotion" 
                      v-model="seqPromo" @change="changePdtPromotion()">
                      <option :value="promo.seqPromo" :key=i v-for="(promo,i) in promoList">
                        {{`${getDateFormat(promo.promoStart)} ~ ${getDateFormat(promo.promoEnd)}>> ${promo.promoName}(${promo.useFlag}) / ${promo.promoCode}`}}</option>
                    </select>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">프로모션명</label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control input-sm" v-model="choicePromotion.promoName">
                    </div>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">프로모션상태</label>
                  <div class="col-md-9">
                    <div class="mb-3 row">
                      <div class="col-md-9" v-if="cPromoFlag === '0'">
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2b" value="0" checked>
                          <label class="form-check-label text-primary" for="flexRadioDefault1">
                            실행
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2c" value="1">
                          <label class="form-check-label text-danger" for="flexRadioDefault2">
                            중지
                          </label>
                        </div>
                      </div>
                      <div class="col-md-9" v-else-if="cPromoFlag === '1'">
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2b" value="0">
                          <label class="form-check-label text-primary" for="flexRadioDefault1">
                            실행
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2c" Value="1" checked>
                          <label class="form-check-label text-danger" for="flexRadioDefault2">
                            중지
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">할인<span class="text-secondary" style="font-size: small;">(원/%)</span></label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <input type="number" class="form-control text-end" v-model="choicePromotion.promoRate" disabled>
                      <select class="form-select form-select-md" aria-label=".form-select-md" disabled>
                        <option value="A" v-if="choicePromotion.promoMethod == 'A'" selected>원</option>
                        <option value="A" v-else>원</option>
                        <option value="P" v-if="choicePromotion.promoMethod == 'P'" selected>%</option>
                        <option value="P" v-else>%</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label">프로모션기간</label>
                  <div class="col-md-9">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">개시</span>
                      <input type="date" class="form-control" v-model="choicePromotion.promoStart">
                      <span class="input-group-text" id="basic-addon1">종료</span>
                      <input type="date" class="form-control" v-model="choicePromotion.promoEnd">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[수량정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" 
              @click="dataBaseTransaction('U','Q');">&nbsp;UPDATE</i>&nbsp; ]</b> <i class="bi bi-exclamation-triangle-fill"></i>자동판매時 주의
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">수량현황</th>
                <td class="col-md-10 text-start" colspan="3">
                  <span class="text-primary"><b>수량관리({{this.salesVolumeInfo.autoSales}})</b>&nbsp;&nbsp;&nbsp;</span>
                  기초수량({{getCurrencyFormat(this.salesVolumeInfo.pdtQty)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  예약수량({{getCurrencyFormat(this.salesVolumeInfo.bookingFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  확정수량({{getCurrencyFormat(this.salesVolumeInfo.soldFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  취소수량({{getCurrencyFormat(this.salesVolumeInfo.cxlFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  <span class="text-danger">안전재고({{getCurrencyFormat(this.salesVolumeInfo.safetyFigure)}})</span>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">재고수량</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_pdtQty" v-model="salesVolumeInfo.pdtQty">
                    <span class="input-group-text">건(개)</span>
                  </div>
                </td>
                <th class="table-secondary col-md-2">안전재고</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_safetyQty" v-model="salesVolumeInfo.safetyFigure">
                    <span class="input-group-text">건(개)</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b v-if="this.salesRoute === 'S'">[옵션정보]</b>
            <b v-else>[옵션정보&nbsp; <i class="mb-2 bi bi bi-caret-down-fill text-end text-primary" style="cursor:pointer" @click="showArea('O');">&nbsp;EDIT</i>&nbsp; ]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th scope="row" class="table-secondary col-md-2">
                  추가옵션(원)
                </th>
                <td>
                  <p class="text-secondary col-md-4">설정없음</p>
                </td>
                <th scope="row" class="table-secondary col-md-2">
                  기타옵션(원)
                  <br>
                  <p><i class="mb-2 bi  bi-eraser text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('D','E')">&nbsp;DELETE</i></p>
                </th>
                <td class="col-md-4">
                  <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                    -&nbsp;{{this.etcOptionInfo[i].etcOptName}}&nbsp;
                    (<span class="text-success text-small">{{getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)}}</span>)
                    <input class="form-check-input my-auto align-middle" type="checkbox" v-model="this.etcOptionInfo[i].reqOptFlag" true-value="Y" false-value="N" id="flexCheckDefault">
                  </div>
                </td>
              </tr>
            </tbody> 
          </table>
          <table class="table table-bordered border-success align-middle w-100" v-if="this.areaFlag === 'O'">
            <i class="bi bi-capslock-fill text-danger fs-4" style="cursor:pointer" @click="dataBaseTransaction(optFlag, 'O')"></i>&nbsp;&nbsp;[CREATE]
            <div class="row border p-sm-2 border-info">
              <div class="mb-3 row">
                <label class="col-md-3 col-form-label">옵션선택</label>
                <div class="col-md-9">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="optFlag" value="E" name="rbt_optFlag">
                    <label class="form-check-label text-success" for="optFlag2">선택옵션</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="optFlag" value="C" name="rbt_optFlag">
                    <label class="form-check-label text-warning" for="optFlag2">선택수준변경</label>
                  </div>
                </div>
              </div>

              <div class="border p-2" v-if="optFlag === 'E'">
                <div class="mb-3 row">
                  <label class="col-md-3 col-form-label mt-2">선택옵션&nbsp;
                    <i class="bi bi-plus-circle-fill text-primary" @click="addEtcOption()"></i>
                    <p class="text-muted text-sm-center" style="font-size: small;">(명칭|성인|소아|유아|할인|삭제|필수)</p>
                  </label>
                  <div class="col-md-9">
                    <div v-for="(etcOption, i) in etcOptions" :key = "i">
                      <div class="col">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control" placeholder="옵션명" :ref="'txt_etcOptName'+ i" v-model="etcOption.etcOptName">
                          <input type="number" class="form-control text-end" placeholder="성인요금" :ref="'txt_etcOptPrice'+ i" v-model="etcOption.etcOptPrice">
                          <input type="number" class="form-control text-end" placeholder="소아요금" :ref="'txt_etcChild'+ i" v-model="etcOption.etcChild">
                          <input type="number" class="form-control text-end" placeholder="유아요금" :ref="'txt_etcInf'+ i" v-model="etcOption.etcInf">
                          <input type="number" class="form-control text-end" placeholder="할인요금" :ref="'txt_etcDis'+ i" v-model="etcOption.etcDis">
                          <!-- <span class="input-group-text">원</span>&nbsp;&nbsp; -->
                          <button type="button" class="btn btn-outline-danger btn-sm" @click="removeEtcOption(i);">
                            <i class="bi bi-dash-circle-fill text-danger"></i>
                          </button> &nbsp;
                          <input class="form-check-input my-auto" type="checkbox" v-model="etcOption.reqOptFlag" true-value="Y" false-value="N" :ref="'chb_reqOptFlag'+i">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToList()">상품리스트</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  
  </main>
</template>

<script>
import moment from 'moment';
import {genCode} from '../assets/js/genCode'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      productInfo: [],
      productTrans: [],
      pdtTransInfo1: [], //기존교통정보
      pdtTransInfo: [],
      pdtPromoInfo: [], //프로모션정보
      pdtOilSCInfo1: [], //기존유류할증
      pdtTermFeeInfo_dp: [], //기존터미널료
      pdtTermFeeInfo_rt: [],
      //salesVolume: {}, //판매수량관리
      dTerm: "", //출발터미널
      rTerm: "", //리턴터미널
      dCur: "", //터미널이용료화폐
      rCur: "",

      salesVolumeInfo: [], //상품판매수량정보

      pdtAddOptExisted: [], //기존추가옵션
      addOptInfo: [], //화면표시용 추가옵션

      etcOptions: [], //추가옵션정보
      

      areaFlag: "",

      // nationList_via: {},
      cityList: [],
      nationList: [],
      areaList: [],
      areaList_tmp: [],
      transList: [],
      //tranList_via: {},
      mobilityList_tmp: [],
      mobilityList_dp: [], //출발편
      mobilityList_rt: [], //리턴편
      termList_dpd: [], //출발시작터미널
      termList_dpa: [], //출발도착터미널
      termList_rtd: [], //리턴시작터미널
      termList_rta: [], //리턴도착터미널

      sRoute: [], //상품구분

      termList_dp: [], //출발터미널
      termList_rt: [], //리턴국터미널
      oilSurcharge_dp: [], //유류할증
      oilSurcharge_rt: [],
      termFee_dp: [], //터미널이용료
      termFee_rt : [],
      termFeeNameList_dp: [], //터미널이용료 명칭
      termFeeNameList_rt : [],

      promoWork: "", //프로모션 적용방식(C/U)
      cPromoFlag: "", //선택프로모션 진행?종료

      productPromotion: [],
      choicePromotion: [], //선택된 프로모션정보
      promoList: [], //해당상품의 프로모션목록

      optFlag: "", //옵션선택플래그

      addOptNameList_l: [], //추가옵션명(중복제거, Local)
      addOptNameList_h: [], //추가옵션명(중복제거, Hotel)
      addOptNameList_e: [], //추가옵션명(중복제거, Etc)
      addOptNameList_s: [], //추가옵션명(중복제거, Single)
      addOptNameList: [],
      pdtcode: "",
      addOptList_l: [], //추가옵션가격(local)
      addOptList_h: [],
      addOptList_e: [],
      addOptList_s: [],

      addOptionInfo: [], //추가옵션내용
      //추가옵션별 정보
      addOptionPrice_l: [],
      addOptionPrice_e: [],
      addOptionPrice_h: [],
      addOptionPrice_s: [],

      // optionFlag1: "N",
      // optionFlag2: "N",
      // optionFlag3: "N",

      etcOptName: [],
      etcOptPrice: [],
      vCnt: 0,
      etcOptionInfo: [], //기타옵션
      reqOptFlag: [], //필수기타옵션플래그

      productPrice: [], //평상시
      productPrice_t: [], //성수기
      productPrice_p: [], //비수기

      useFlag_g: "", //시즌가격사용여부
      useFlag_t: "",
      useFlag_p: "",

      promoStart: "",
      promoEnd: "",

      //부록패턴 템플릿
      patternList_P: [], //안내사항패턴리스트
      patternList_C: [], //취소안내패턴리스트
      patternList_L: [], //유의사항패턴리스트
      patternCode_P: "", //안내사항패턴코드
      patternCode_C: "",  //취소안내패턴코드
      patternCode_L: "",  //유의사항패턴코드
      sel_patternList_P: [], //선택된 안내사항패턴리스트
      sel_patternList_C: [], //선택된 취소안내패턴리스트
      sel_patternList_L: [], //선택된 유의사항패턴리스트

      //멤버십 할인정보
      membershipDC: {
        // TB_Product_prc
        pdtCode: "",
        prcCode: "", //멤버십할인코드
        ageGroup: "",

        //pdtPrice1: 0, //평시
        pdtPriceName1: "", //권종이명
        pdtPrice: 0,
        pdtPrice_S: 0,
        
        useFlag: "", //0:Y, 1:N
        insId: "",
      },
      etcOption: {}, //기타옵션

      pdtPriceInfo: [], //상품가격이 세팅된 경우-해당정보

      assCodeList: []         //연계상품거래처코드
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.ntCd = this.$route.query.ntCd;
    
    this.getSalesRoute();
    this.getProductInfo();
    this.getProductTransInfoExited(); //기존교통정보
    this.getProductTransInfo();
    this.getProductPrice();
    this.getPdtPromotion(); //상품프로모션
    // this.getPdtOilSurchargeExited(); //기존상품 유류할증료
    // this.getPdtTerminalFeeExited(); //기존상품 유류할증료
    this.getSalesVolumeInfo(); //판매수량관리 정보

    this.getProductPromotion(); //상품프로모션정보
    this.getPromotionList();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getTransList();
    this.getMobilityList();

    this.getAssoCodeList();       //연계상품코드리스트
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    // callTest(){
    //   alert("TEST:" + this.etcOptionInfo.length);
    //   //console.log("reqOptFlag:", this.etcOptionInfo.reqOptFlag);
    //   let i = 0;
    //   while(i < this.etcOptionInfo.length) {
    //     console.log("reqOptFlag:", this.etcOptionInfo[i].etcOptName,":",this.etcOptionInfo[i].reqOptFlag);
    //     i++
    //   }
    // },

    //연계상품작업체크
    previewAssoPdt(assoFlag) 
    {
      if(!assoFlag)
      {
        this.$swal.fire("","연계상품전용 미리보기입니다", "warning")
        return false
      }
      
      let linkStyle_tmp = this.assCodeList.filter((item) => {
        return item.assoCode === assoFlag
      })
      let linkStyle = linkStyle_tmp[0].linkStyle

      // 상품미리보기
      if(linkStyle === "1s")
      {
        // window.open(
        //   `/https://ntabi.co.kr/sproductDetail_asso?productId=${this.productId}&salesRoute=T&asso=${assoFlag}`,
        //   'PreView',
        //   'height=1000,width=1200,top=100,left=100,resizable=yes,scrollbars=yes'
        // );
        window.open(
          `/productDetail_asso?productId=${this.productId}&salesRoute=T&asso=${assoFlag}`,
          'PreView',
          'height=1000,width=1200,top=100,left=100,resizable=yes,scrollbars=yes'
        );
      }
      else
      {
        //window.open('/https://ntabi.co.kr/bustour_asso?assoFlag=' + assoFlag,'PreView', 'height=1000,width=1200,top=100,left=100,resizable=yes,scrollbars=yes')
        window.open('/bustour_asso?assoFlag=' + assoFlag,'PreView', 'height=1000,width=1200,top=100,left=100,resizable=yes,scrollbars=yes')
      }
    },

    //연계상품코드리스트
    async getAssoCodeList() {
      let assCodeList = await this.$api("/api/assCodeList", {});
      
      if(assCodeList.length > 0)
      {
        this.assCodeList = assCodeList
      }
      console.log("assCodeList:",this.assCodeList);
    },

    async setAssoProduct(aCode){
      this.$swal.fire({
        icon: 'question',
        text: '연계상품은 홈페이지에 일반게시되지 않고 연계사이트를 통해 게시/판매됩니다.',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니요'
      }).then(async (result) => 
      {
        if (result.isConfirmed) 
        {
          if(aCode === "noVal")
          {
            let assoCode = ""
            let orgPdtPrice = 0
            let updAssoCode = await this.$api("/api/upd_assCode", {param: [
              assoCode, this.user.handlerId, orgPdtPrice, this.productId
            ]})

            if(updAssoCode.affectedRows < 0)
            {
              this.$swal.fire("","요청된 작업 처리 중 문제가 발생하였습니다. 재시도해주세요", "warning")
              return false
            }
            else
            {
              this.$swal.fire("","당해상품의 연계가 해지되었습니다. 판매상태, 판매조건, 가격등을 잘 체크해주세요.", "info")
              location.reload(true)
            }
          }
          else
          {
            const { value: orgPrice } = await this.$swal.fire({
              html: '할인 전 판매가격을 입력하세요(노말가격기준)',
              input: 'text',
              //inputLabel: '뱃지표시',
              // inputPlaceholder: 할인전가격
            })

            console.log("orgPrice:",orgPrice)

            if (orgPrice) {
              this.$swal.fire('',`할인전 가격: ${orgPrice}`)
              
              let orgPdtPrice = orgPrice.trim()   //혹시 모를 여백제거

              let updAssoCode = await this.$api("/api/upd_assCode", {param: [
                aCode, this.user.handlerId, orgPdtPrice, this.productId
              ]})

              if(updAssoCode.affectedRows < 0)
              {
                this.$swal.fire("","요청된 작업 처리 중 문제가 발생하였습니다. 재시도해주세요", "warning")
                return false
              }
              else
              {
                this.$swal.fire("","연계상품으로 변경되었습니다. 판매상태, 판매조건, 가격등을 잘 체크해주세요.", "info")
                location.reload(true)
              }
            }
          }
          // let assoCode = ""
          // let updAssoCode = await this.$api("/api/upd_assCode", {param: [
          //   assoCode, this.user.handlerId, this.productId
          // ]})

          // if(updAssoCode.affectedRows < 0)
          // {
          //   this.$swal.fire("","요청된 작업 처리 중 문제가 발생하였습니다. 재시도해주세요", "warning")
          //   return false
          // }
          // else
          // {
          //   this.$swal.fire("","요청된 작업이 완료되었습니다. 판매조건과 가격등을 잘 체크해주세요.", "info")
          //   location.reload(true)
          // }
        }
      })
    },

    async updateFirstPayInfo(pCode){
      console.log(pCode)
      if(!this.productInfo.firstPayment || this.productInfo.firstPayment < 0)
      {
        this.$refs.firstPayment.focus()
        this.$swal.fire("","1차 결제액을 체크해주세요", "warninig")
        return false
      }

      //1차결제금액 저장
      let ins_firstPayInfo = await this.$api("/api/ins_firstPayInfo", {param: [
        this.productInfo.firstPayment, "P", pCode
      ]})
      if(ins_firstPayInfo.affectedRows < 0)
      {
        this.$swal.fire("","결제방법 저장 중 에러가 발생했습니다. 재시도해주세요", "warning")
        return false;
      }
      else
      {
        this.$swal.fire("","1차 결제금액정보가 변경되었습니다.", "info")
        location.reload(true)        
      }
    },

    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },
    async getProductInfo() 
    {
      let productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);

      //멤버십할인정보
      this.productInfo.dcForMem_P = (this.productInfo.dcForMem_P == null || this.productInfo.dcForMem_P == "") ? 0 : this.productInfo.dcForMem_P
      this.productInfo.dcRateForMem_P = (this.productInfo.dcRateForMem_P == null || this.productInfo.dcRateForMem_P == "") ? "P" : this.productInfo.dcRateForMem_P
      this.productInfo.dcForMem_S = (this.productInfo.dcForMem_S == null || this.productInfo.dcForMem_S == "") ? 0 : this.productInfo.dcForMem_S
      this.productInfo.dcRateForMem_S = (this.productInfo.dcRateForMem_S == null || this.productInfo.dcRateForMem_S == "") ? "P" : this.productInfo.dcRateForMem_S

      this.productInfo.dcRateForPre = (this.productInfo.dcRateForPre == null || this.productInfo.dcRateForPre == "") ? 0 : this.productInfo.dcRateForPre
      this.productInfo.dcRateForStd = (this.productInfo.dcRateForStd == null || this.productInfo.dcRateForStd == "") ? 0 : this.productInfo.dcRateForStd
      this.productInfo.dcForStd = (this.productInfo.dcForStd == null || this.productInfo.dcForStd == "") ? 0 : this.productInfo.dcForStd

      //상품판매처의 기본값은 nt & nd
      this.productInfo.sellerCode_nt = (productInfo[0].sellerCode_nt === 'Y' || productInfo[0].sellerCode_nt === null || productInfo[0].sellerCode_nt === undefined) ? "Y" : "N";
      this.productInfo.sellerCode_nd = (productInfo[0].sellerCode_nd === 'Y' || productInfo[0].sellerCode_nd === null || productInfo[0].sellerCode_nd === undefined) ? "Y" : "N";      
      console.log("productInfo:",this.productInfo);
      
      if(this.productInfo.etcOptionFlag === "Y") {
        this.getEtcOptionInfo();
      }
      this.getPatternList();
      this.getManagerList();
    },
    async getManagerList() {
      let managerInfo = await this.$api("/api/handlerInfo", {});
      
      if(managerInfo.length > 0)
      {
        this.managerInfo = managerInfo.filter(item => {
          return item.manFlag === 'Y'
        })
      }
      //console.log("managerInfo:",this.managerInfo);
    },
    async getPatternList() {
      let patternList = await this.$api("/api/patternList", {});
      //this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      this.patternList_P = patternList.filter(e => {return e.pCat === "P" && e.pUseFlag === "Y"})
      this.patternList_C = patternList.filter(e => {return e.pCat === "C" && e.pUseFlag === "Y"})
      this.patternList_L = patternList.filter(e => {return e.pCat === "L" && e.pUseFlag === "Y"})
    },
    set_supplement(param) {
      //alert("patternCode_P"+this.patternCode_P);
      if(param === "P")
      {
        this.sel_patternList_P = this.patternList_P.filter(e => {return e.patternCode === this.patternCode_P });
        //console.log("sel_patternList_P:",this.sel_patternList_P);
        this.productInfo.supPrecaution = this.sel_patternList_P[0].pContent;
      }
      else if(param === "C")
      {
        this.sel_patternList_C = this.patternList_C.filter(e => {return e.patternCode === this.patternCode_C });
        //console.log("sel_patternList_P:",this.sel_patternList_P);
        this.productInfo.supCancellation = this.sel_patternList_C[0].pContent;
      }
      else if(param === "L")
      {
        this.sel_patternList_L = this.patternList_L.filter(e => {return e.patternCode === this.patternCode_L });
        //console.log("sel_patternList_P:",this.sel_patternList_P);
        this.productInfo.supLookup = this.sel_patternList_L[0].pContent;
      }      
    },    
    //기존상품 교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },
    async getProductTransInfo() {
      let pdtTransInfo = await this.$api("/api/pdtTransInfo", {param: [this.productId]});
      if(this.pdtTransInfo.length > 0){
        this.pdtTransInfo = pdtTransInfo[0];
      }
      //console.log("pdtTransInfo:",this.pdtTransInfo);
      this.pageOpen();
      //this.getAddRoute_dp()
    },
    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },
    //기존상품 유류할증료
    async getPdtOilSurchargeExited() {
      let pdtOilSCInfo1 = await this.$api("/api/pdtOilSurchage_existed", {param: [this.productId]});
      if(pdtOilSCInfo1.length > 0) {
        this.pdtOilSCInfo1 = pdtOilSCInfo1[0];
      }
    },
    //기존상품 터미널이용료
    async getPdtTerminalFeeExited() {
      let pdtTermFeeInfo_dp = await this.$api("/api/pdtTerminalFeeD_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_dp.length > 0) {
        this.pdtTermFeeInfo_dp = pdtTermFeeInfo_dp;
        this.dTerm = pdtTermFeeInfo_dp[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp[0].dCur;
      }
      //console.log("pdtTermFeeInfo_dp:",this.pdtTermFeeInfo_dp);
      let pdtTermFeeInfo_rt = await this.$api("/api/pdtTerminalFeeR_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_rt.length > 0) {
        this.pdtTermFeeInfo_rt = pdtTermFeeInfo_rt;
        this.rTerm = pdtTermFeeInfo_rt[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt[0].rCur;
        //console.log("pdtTermFeeInfo_rt:",this.pdtTermFeeInfo_rt);
      }
    },
    // //배열내 중복제거
    // func_Distinct(value, index, self) {
    //   return self.indexOf(value) === index;
    // },
    //기존상품 추가옵션정보
    async getPdtAddOptionExited() {
      //대상상품에 적용된 추가옵션코드 추출
      let addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed", {param: [this.productId]});
      //console.log("addOptCodes:",addOptCodes);
      let addOptInfo = await this.$api("/api/pdtAddOption_existed", {param: [this.productId]});
      //console.log("addOptInfo:",addOptInfo);
      let i=0;
      let pdtAddOptExisted = [];
      while(i<addOptCodes.length) {
        //alert(addOptCodes[i].pdtCode);
        pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));
        i++;
      }
      if(pdtAddOptExisted.length > 0){
        this.pdtAddOptExisted = pdtAddOptExisted;
      }
    },
    //상품별 수량관리정보
    async getSalesVolumeInfo() {
      let salesVolumeInfo = await this.$api("/api/salesVolumeInfo", {param: [this.productId]});
      if(salesVolumeInfo.length > 0) {
        this.salesVolumeInfo = salesVolumeInfo[0];
      }
      console.log("salesVolumeInfo",this.salesVolumeInfo);
    },
    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    //넘어온 파라메타로 교통편표시
    async pageOpen(){
      //alert("trans:"+this.productInfo.transFlag);
      this.getAreaList_pageOpen()
      if(this.salesRoute === "T" || this.salesRoute === "L") {
        this.viewDpMobilityList_pageOpen();
        this.viewRtMobilityList_pageOpen();
        this.getDpTermList_pageOpen();
        this.getRtTermList_pageOpen();
      }
    },
    
    /////공통함수
    /** 개행표시 */
    getStringFormat(text) 
    {
      return this.$stringFormat(text);
    },    
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //페이지 오픈시 교통정보 호출
    async getAreaList_pageOpen() {
      //console.log("productInfo:",this.productInfo);
      let areaList1 = await this.$api("/api/areaList", {});
      this.areaList = areaList1.filter(e => {return e.categoryCode === this.ntCd});
    },
    async viewDpMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd1});
    },
    async viewRtMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd2});
    },
    async getDpTermList_pageOpen() {
      // let param4 = (this.pdtTransInfo.trCd1 === "SHP") ? "ptCd" : "apCd"
      let param4 = "";
      if(this.pdtTransInfo.trCd1 === "SHP") {
        param4 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd1 === "FLT") {
        param4 = "apCd";
      }
      else {
        param4 = "";
      }
      if(param4 === "ptCd" || param4 === "apCd") {
        this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
        //출발도착터미널
        this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param4]});
      }
      else{
        this.termList_dpd = "";
        this.termList_dpa = "";
      }
      //console.log("TR:",param4,"/termListD:",this.termList_dpd,"/termListA:",this.termList_dpa);
    },
    async getRtTermList_pageOpen() {
      //let param5 = (this.pdtTransInfo.trCd2 === "SHP") ? "ptCd" : "apCd"
      let param5 = "";
      if(this.pdtTransInfo.trCd2 === "SHP") {
        param5 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd2 === "FLT") {
        param5 = "apCd";
      }
      else {
        param5 = "";
      }
      //this.termList_rtd = await this.$api("/api/termList", {param: [this.productInfo.ntCd, param5]});
      if(param5 === "ptCd" || param5 === "apCd") {
        this.termList_rtd = await this.$api("/api/termList", {param: [this.ntCd, param5]});
        this.termList_rta = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param5]});
      }
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },
    changeNationList() {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(this.productInfo.ntCd);
    },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //console.log("mobilityList_tmp",this.mobilityList_tmp);
    },
    changeTransList() {
      this.viewMobilityList(this.productInfo.trCd);
      //console.log("trCd:",this.transCode);
    },
    viewMobilityList(param2){
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList);
    },
    changeMobilityList() {
      this.getTermList(this.productInfo.ntCd, this.productInfo.trCd);
    },
    async getTermList(param3, param4) {
      //console.log("prarms:",param3,"/",param4);
      let param5 = (param4 === "SHP") ? "ptCd" : "apCd"
      this.termList_dp = await this.$api("/api/termList", {param: ['KR', param5]});
      this.termList_rt = await this.$api("/api/termList", {param: [param3, param5]});
    },

    //교통수단변경->교통편획득
    changeDpTransList() {
      this.pdtTransInfo.mbCd1 = "";
      this.pdtTransInfo.dpdCd = "";
      this.pdtTransInfo.dpaCd = "";
      this.viewDpMobilityList(this.pdtTransInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.pdtTransInfo.mbCd2 = "";
      this.pdtTransInfo.rtdCd = "";
      this.pdtTransInfo.rtaCd = "";
      this.viewRtMobilityList(this.pdtTransInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    changeDpMobilityList() {
      this.getDpTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_rt);
    },
    //상품가격
    async getProductPrice() {
      this.addOptCheck(this.productInfo.salesRoute)
        //this.getAddOption();

      if(this.productInfo.addOptionFlag === "Y"){
        this.getPdtAddOptionExited();
        this.getAddOption();
      }
    },
    //수정영역 표시(비표시)
    showArea(param) {
      this.areaFlag = (this.areaFlag === param) ? "N"+param : param;
      if(this.areaFlag === "NT") 
      {
        //alert(param+"의 반대: N"+param);
        this.pdtTransInfo.trCd1 = "";
        this.pdtTransInfo.mbCd1 = "";
        this.pdtTransInfo.dpdCd = "";
        this.pdtTransInfo.dpaCd = "";
        this.pdtTransInfo.transRemarks_dp = "";
        this.pdtTransInfo.oilCd_dp = "";
        this.pdtTransInfo.termCd_dp = "";
        this.pdtTransInfo.dpTpCode = "";
        this.pdtTransInfo.d_dptime = "";
        this.pdtTransInfo.d_artime = "";
        
        this.pdtTransInfo.trCd2 = "";
        this.pdtTransInfo.mbCd2 = "";
        this.pdtTransInfo.rtdCd = "";
        this.pdtTransInfo.rtaCd = "";
        this.pdtTransInfo.transRemarks_ar = "";
        this.pdtTransInfo.oilCd_rt = "";
        this.pdtTransInfo.termCd_rt = "";
        this.pdtTransInfo.rtTpCode = "";
        this.pdtTransInfo.r_dptime = "";
        this.pdtTransInfo.r_artime = "";
      }
      else if(this.areaFlag === "NP") 
      {
        console.log("NP:", this.areaFlag)
      }
    },
    //addOption정보
    async getAddOption() {
      let addOptionInfo_tmp = await this.$api("/api/addOptionInfo", {param: [this.productId]});
      //console.log("addOptionInfo_tmp:",addOptionInfo_tmp);
      let i = 0;
      //let j = 0;
      while(i < addOptionInfo_tmp.length){
        switch(addOptionInfo_tmp[i].prcCode.charAt(1)) {
          case "L": this.addOptionPrice_l = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
                    this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_l[0].pdtCode);
            //console.log("addOptionPrice_l:",this.addOptionPrice_l);
          break;
          case "H": this.addOptionPrice_h = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
                    this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_h[0].pdtCode);
            //console.log("addOptionPrice_h:",this.addOptionPrice_h);
          break;
          case "E": this.addOptionPrice_e = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
                    this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_e[0].pdtCode);
            //console.log("addOptionPrice_e:",this.addOptionPrice_e);
          break;
          case "S": this.addOptionPrice_s = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
                    this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_s[0].pdtCode);
            //console.log("addOptionPrice_s:",this.addOptionPrice_s);
          break;
          default: return this.$swal.fire("","CaseI: 오류가 발생했습니다./n관리자에 문의해주세요.","error");
        }

        let addOptionPrice_tmp = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
        //console.log("addOptionPrice_tmp:",addOptionPrice_tmp[i].pdtPrice);
        let key = addOptionInfo_tmp[i].prcCode;
        this.addOptionInfo[key] = addOptionPrice_tmp;
        i++;
      }
    },
    //기타옵션획득
    async getEtcOptionInfo() {
      this.etcOptionInfo = await this.$api("/api/etcOptionInfo", {param: [this.productId]});
      console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    async getEtcFeeInfo(p1,p2,p3,p4) {
      let drFlag = p1;
      let liCd = (this.productInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4

      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
      }
    },
    async getTermFeeInfo(f,t1){
      if(f === "D") {
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },
    async changeTermFeeNameList(f,t1){
      if(f === "D") {
        let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_dp = termFee_dp;
        //console.log("termFee_dp",this.termFee_dp);
      }
      else if(f === "R") {
        let termFee_rt = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_rt = termFee_rt;
        //console.log("termFee_rt",this .termFee_rt);
      }
    },
    async changePdtPromotion() {
      let choicePromotion = this.promoList.filter(e => {return e.seqPromo === this.seqPromo});
      this.choicePromotion = choicePromotion[0];
      this.choicePromotion.promoStart = moment(this.choicePromotion.promoStart).format("YYYY-MM-DD");
      this.choicePromotion.promoEnd = moment(this.choicePromotion.promoEnd).format("YYYY-MM-DD");
      this.cPromoFlag = this.choicePromotion.useFlag;
    },
    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);

    },
    //추가옵션 리스트박스용 상품명 추출(중복제거)
    async addOptCheck(val) {
      //let opt = val;
      let addOptName_tmp = await this.$api("/api/addOptionNameList", {param: [this.productInfo.ntCd]});
      //console.log("addOptName_tmp:",addOptName_tmp);
      //let summaryData = this.eOrderList.filter(e => {return e.orderStatus == "30"}); //배열로 반환
      if(val === "T") {
        this.addOptNameList_l = addOptName_tmp.filter(e => {return e.salesRoute === "L"});
        this.addOptNameList_h = addOptName_tmp.filter(e => {return e.salesRoute === "H"});
        this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
        this.addOptNameList = addOptName_tmp.filter(e => {return e.salesRoute != "T"});
        //console.log("addOptNameList:",this.addOptNameList);
      }
      else if(val === "L") {
        this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
        this.addOptNameList = addOptName_tmp.filter(e => {return e.salesRoute != "T" && e.salesRoute != "L"});
        //console.log("addOptNameList:",this.addOptNameList);
      }
      else if(val === "E") {
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
      }
      //배열내부 중복제거
      //this.addOptName_local = Array.from(new Set(addOptName_local_tmp));
    },
    async changeAddOptN(param1, param2) {
      let addOptList_tmp = await this.$api("/api/addOptionList", {param: [this.productInfo.ntCd]});
      //let addOptList_filterd = [];
      let addOptList_filterd = addOptList_tmp.filter(e => {return e.salesRoute === param1 && e.pdtCode === param2});
      //console.log("addOptList_tmp:",addOptList_tmp);

      switch(param1) {
        case "L": this.addOptList_l = addOptList_filterd;
          //console.log("addOptList_l:",this.addOptList_l);
        break;
        case "H": this.addOptList_h = addOptList_filterd;
          //console.log("addOptList_h:",this.addOptList_h);
        break;
        case "E": this.addOptList_e = addOptList_filterd;
          //console.log("addOptList_e:",this.addOptList_e);
        break;
        case "S": this.addOptList_s = addOptList_filterd;
          //console.log("addOptList_s:",this.addOptList_s);
        break;
        default: return this.$swal.fire("","CaseII: 오류가 발생했습니다./n관리자에 문의해주세요.","error");
      }
    },
    //기타옵션(->추가옵션) refactoring(20230226)
    async addEtcOption() 
    {
      let eOpt = "EOP-"
      let eOptCode = await this.genCode(eOpt)
      //console.log("eOptCode:", eOptCode)
      this.etcOptions.push({
        etcOptName: "", etcOptPrice: 0, etcChild: 0, etcInf: 0, etcDis: 0, etcOptionFlag: 'N', etcOptCode: eOptCode
      })
    },
    //기타옵션제거
    removeEtcOption(val) 
    {
      this.etcOptions.splice(val, 1);
    },

    // //상품가격관리페이지로 이동
    // goToOptionSetPage(productId) {
    //   this.$router.push({path: '/productOptionInsert', query: {productId: productId, sRoute: this.salesRoute}});
    // },
    //상품옵션관리페이지로 이동
    goToPriceSetPage(productId) {
      this.$router.push({path: '/productPriceSet', query: {productId: productId, sRoute: this.salesRoute}});
    },

    //멤버십 할인률관리페이지로 이동
    goToMembershipSetPage(productId) {
      //console.log("productId:", productId)
      this.$router.push({path: '/productMembershipSet', query: {productId: productId, sRoute: this.salesRoute}});
    },

    //판매기간 중 멤버십 할인율 설정
    setMembershipDC(){
      this.priceInsert(this.productInfo.salesStart, this.productInfo.salesEnd)
    },

    //동반할인세팅
    async setCompanionDC() {
      let setCompanionDC = await this.$api("/api/setCompanionDC", {param: [
        this.productInfo.companionDC_P, this.productInfo.companionDC_S, this.productId
      ]});
      //console.log("setCompanionDC:", setCompanionDC);
      if(setCompanionDC.affectedRows > 0)
      {
        this.$swal.fire("","동반할인율이 저장되었습니다.", "info")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("","에러가 발생했습니다.", "info")
        return false
      }
    },

    async makeNoRefund(sDate_tmp, eDate, flag)
    {
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime();
      let catCode = "MS"

      if(sDate_tmp == "") 
      {
        this.$refs.txt_salesStart.focus()
        return this.$swal.fire("","멤버십환급율 등록시작일을 입력하세요","warning");
      }
      if(eDate == "") 
      {
        this.$refs.txt_salesEnd.focus()
        return this.$swal.fire("","멤버십환급율 등록종료일을 입력하세요","warning");
      }
      
      let sDate =  sDate_tmp.replace(/-\d{2}$/, '');

      let startDate = sDate+"-01"
      //console.log("startDate:",startDate,", eDate:",eDate)

      //멤버십 할인등록정보
      this.membershipDC.pdtCode = this.productId;
      this.membershipDC.prcCode = catCode+"-"+ymd+"-"+timeStamp;
      //this.membershipDC.insDate = `${yyyy}-${mm}-${dd}`;
      this.membershipDC.insId = this.user.handlerId;

      if(flag === "pre")
      {
        this.productInfo.dcRateForPre = 0
      }
      else if(flag === "std")
      {
        this.productInfo.dcRateForStd = 0
      }

      //기존 세팅값 정리(중복된 월의 세팅값 무효처리)
      let nullifyDubDate = await this.$api("/api/nullifyDubDate_mem", {param: [
        this.productId, startDate, eDate
      ]});

      if(nullifyDubDate.affectedRows < 0 || nullifyDubDate.affectedRows === undefined)
      {
        this.$swal.fire('','중복된 날짜가 없습니다', 'info')
      }

      if(this.productInfo.salesRoute === "L")
      {
        let ins_membershipDC = await this.$api("/api/proc_setMemberDC", {param: [
          this.productId, this.membershipDC.prcCode,startDate, eDate, this.membershipDC.insId, this.productInfo.dcRateForPre, this.productInfo.dcRateForStd
        ]})
        if(ins_membershipDC.affectedRows >= 0)
        {
          this.$swal.fire('','환급액이 0으로 변경되었습니다. 기본정보 UPDATE를 눌러 저장하세요.', 'info')
        }
        else
        {
          this.$swal.fire('','세팅실패('+ ins_membershipDC.error.sqlMessage +')', 'error')
        }
      }
      else
      {
        this.$swal.fire('', '멤버십 환급설정 대상상품이 아닙니다.', 'error')
        return false;
      }
    },

    async priceInsert(sDate_tmp, eDate)
    {
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime();
      let catCode = "MS"

      if(sDate_tmp == "") 
      {
        this.$refs.txt_salesStart.focus()
        return this.$swal.fire("","멤버십환급율 등록시작일을 입력하세요","warning");
      }
      if(eDate == "") 
      {
        this.$refs.txt_salesEnd.focus()
        return this.$swal.fire("","멤버십환급율 등록종료일을 입력하세요","warning");
      }
      
      let sDate =  sDate_tmp.replace(/-\d{2}$/, '');

      let startDate = sDate+"-01"
      //console.log("startDate:",startDate,", eDate:",eDate)

      //멤버십 할인등록정보
      this.membershipDC.pdtCode = this.productId;
      this.membershipDC.prcCode = catCode+"-"+ymd+"-"+timeStamp;
      //this.membershipDC.insDate = `${yyyy}-${mm}-${dd}`;
      this.membershipDC.insId = this.user.handlerId;

      // 등록전 확인
      this.$swal.fire({
        title: "",
        html: '1.이 버튼은 판매기간 중 환급율 내용을 생성하는 버튼입니다.<br><b>2.환급율생성 전에 우선 상품정보를 UPDATE하세요.</b><br>3.중복된 기간이 있는 경우 입력된 환급율로 변경됩니다.',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소',
        icon: 'warning'
      }).then(async (result) => {
        if (result.isConfirmed) {

          //기존 세팅값 정리(중복된 월의 세팅값 무효처리)
          let nullifyDubDate = await this.$api("/api/nullifyDubDate_mem", {param: [
            this.productId, startDate, eDate
          ]});
          if(nullifyDubDate.affectedRows < 0 || nullifyDubDate.affectedRows === undefined)
          {
            this.$swal.fire('','중복된 날짜가 없습니다', 'info')
          }

          if(this.productInfo.salesRoute === "L")
          {
            let ins_membershipDC = await this.$api("/api/proc_setMemberDC", {param: [
              this.productId, this.membershipDC.prcCode,startDate, eDate, this.membershipDC.insId, this.productInfo.dcRateForPre, this.productInfo.dcRateForStd
            ]})

            if(ins_membershipDC.affectedRows >= 0)
            {
              this.$swal.fire('','멤버십환급율 세팅이 준비되었습니다.<br>멤버십 환급율설정을 누르시면 설정페이지로 이동합니다.', 'info')
            }
            else
            {
              this.$swal.fire('','세팅실패('+ ins_membershipDC.error.sqlMessage +')', 'error')
            }
          }
          else
          {
            this.$swal.fire('', '멤버십 환급설정 대상상품이 아닙니다.', 'error')
            return false;
          }
        }
      })
    },

    goToList() {
      this.$router.push({path: '/sales'});
    },

    changeNoRefund(flag, param){
      if(param === "N")
      {
        this.$swal.fire("", "멤버십에서 제외되어 환급액을 0으로 변경합니다", "info")
        this.makeNoRefund(this.productInfo.salesStart, this.productInfo.salesEnd, flag)
      }
    },
    
    dataBaseTransaction(p1,p2){
      this.productInfo.modId = this.user.handlerId;
      
      if(p1 === "U" && p2 ==="M") {
        this.$swal.fire({
        icon: 'question',
        text: '상품정보변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let resUpd_product_m = await this.$api("/api/pdtBasicInfoUpdate", {param: [
              this.productInfo.pdtNameKor,this.productInfo.pdtNameEng,this.productInfo.ntCd,this.productInfo.arCd,this.productInfo.tripStart,this.productInfo.tripEnd,
              this.productInfo.salesStart,this.productInfo.salesEnd,this.productInfo.nominalAmt,this.productInfo.basicMargin,this.productInfo.marginMethod,
              this.productInfo.vatPrice,this.productInfo.pdtKeyword,this.productInfo.pdtRemarks, this.productInfo.modId, this.productInfo.ctCd, 
              this.productInfo.unableDpDate, this.productInfo.userPoint, this.productInfo.userPointMethod,this.productInfo.nominalAmt_t,
              this.productInfo.nominalAmt_p,this.productInfo.sFlag_g,this.productInfo.sFlag_t,this.productInfo.sFlag_p,this.productInfo.stayCnt, this.productInfo.sellerCode_nt, this.productInfo.sellerCode_nd, this.productInfo.inContent, this.productInfo.exContent, this.productInfo.supPrecaution, this.productInfo.supCancellation,this.productInfo.supLookup,this.productInfo.manId,this.productInfo.memLevel_P,this.productInfo.memLevel_S,this.productInfo.dcForStd,this.productInfo.dcRateForMem_P,this.productInfo.dcForMem_P,this.productInfo.dcRateForMem_S,this.productInfo.dcForMem_S,this.productInfo.dcRateForPre,this.productInfo.dcRateForStd,
              this.productId
            ]});
            console.log("errCheck:", Object.keys(resUpd_product_m)[0]);
            // 인서트오류
            if(resUpd_product_m.affectedRows < 0 || resUpd_product_m.affectedRows === undefined){
              this.$swal.fire('', '상품기본정보 업데이트 오류', 'alert')
            }
            else{
              this.$swal.fire('', '상품 기본정보 변경완료', 'success')
              location.reload();
            }
          }
        })
      }
      else if(p1==="U" && p2==="T") {
        this.$swal.fire({
        icon: 'question',
        text: '교통정보변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let resUpd_pdtTransInfo = await this.$api("/api/pdtTransInfoUpdate", {param: [
              this.pdtTransInfo.trCd1,this.pdtTransInfo.mbCd1,this.pdtTransInfo.dpdCd,this.pdtTransInfo.dpaCd,this.pdtTransInfo.transRemarks_dp,
              this.pdtTransInfo.oilCd_dp,this.pdtTransInfo.termCd_dp,
              this.pdtTransInfo.trCd2,this.pdtTransInfo.mbCd2,this.pdtTransInfo.rtdCd,this.pdtTransInfo.rtaCd,this.pdtTransInfo.transRemarks_ar,
              this.pdtTransInfo.oilCd_rt,this.pdtTransInfo.termCd_rt,
              this.user.handlerId, this.pdtTransInfo.dpTpCode, this.pdtTransInfo.d_dpTime, this.pdtTransInfo.d_arTime, this.pdtTransInfo.rtTpCode, this.pdtTransInfo.r_dpTime, this.pdtTransInfo.r_arTime,
              this.productId
            ]});
            //console.log("errCheck:", Object.keys(resUpd_pdtTransInfo)[0]);
            // 인서트오류
            if(resUpd_pdtTransInfo.affectedRows < 0 || resUpd_pdtTransInfo.affectedRows === undefined){
              this.$swal.fire('', '교통정보 업데이트 오류', 'alert')
            }
            else{
              this.$swal.fire('', '교통정보 변경완료', 'success')
              location.reload();
            }
          }
        })
      }
      else if (p1==="M" && p2 ==="P") {
        this.$swal.fire({
        icon: 'question',
        text: '시즌별 권종금액변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let i = 0;
            let j = 0;
            let k = 0;
            let sUseFlag = "";
            let sSeasonCode = "";
            //console.log("G:",this.useFlag_g,"P:",this.useFlag_t,"P:",this.useFlag_p);

            while(i < this.productPrice.length) {
              sUseFlag = this.useFlag_g;
              sSeasonCode = "G";
              let resUpd_pdtPrice = await this.$api("/api/pdtPriceUpdate", {param: [
                this.productPrice[i].pdtPrice,this.user.handlerId,sUseFlag,this.productId,this.productPrice[i].agegroup,sSeasonCode
              ]});
              // 인서트오류
              if(resUpd_pdtPrice.affectedRows < 0 || resUpd_pdtPrice.affectedRows === undefined){
                alert("평상시 권종금액 변경 중 오류가 발생했습니다\n확인 후 관리자에게 문의바랍니다.");
              }
              i++;
            }
            while(j < this.productPrice_t.length) {
              sUseFlag = this.useFlag_t;
              sSeasonCode = "T";
              let resUpd_pdtPrice_t = await this.$api("/api/pdtPriceUpdate", {param: [
                this.productPrice_t[j].pdtPrice,this.user.handlerId,sUseFlag,this.productId,this.productPrice_t[j].agegroup,sSeasonCode
              ]});
              // 인서트오류
              if(resUpd_pdtPrice_t.affectedRows < 0 || resUpd_pdtPrice_t.affectedRows === undefined){
                alert("성수기 권종금액 변경 중 오류가 발생했습니다\n확인 후 관리자에게 문의바랍니다.");
              }
              j++;
            }
            while(k < this.productPrice_p.length) {
              sUseFlag = this.useFlag_p;
              sSeasonCode = "P";
              let resUpd_pdtPrice_p = await this.$api("/api/pdtPriceUpdate", {param: [
                this.productPrice_p[k].pdtPrice,this.user.handlerId,sUseFlag,this.productId,this.productPrice_p[k].agegroup,sSeasonCode
              ]});
              // 인서트오류
              if(resUpd_pdtPrice_p.affectedRows < 0 || resUpd_pdtPrice_p.affectedRows === undefined){
                alert("비수기 권종금액 변경 중 오류가 발생했습니다\n확인 후 관리자에게 문의바랍니다.");
              }
              k++;
            }
            this.$swal.fire('', '수정완료', 'success');
            location.reload();
          }
        })
      }
      else if(p1==="C" && p2==="P") {
        this.$swal.fire({
        icon: 'question',
        text: '프로모션 신규생성?',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let timeStamp = "";
            let today = new Date();   
            let yyyy = today.getFullYear();
            let mm = today.getMonth()+1;
            let dd = today.getDate();
            let ymd = `${yyyy}${mm}${dd}`
            timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
            let promoCode = "PM-"+ymd+"-"+timeStamp    //프로모션코드

            let resIns_pdtPromotion = await this.$api("/api/productPromoInsert_new", 
              {param: [this.productId, this.salesRoute, this.productPromotion.promoStart, this.productPromotion.promoEnd,
                this.productPromotion.promoMethod, this.productPromotion.promoRate, this.user.handlerId,// this.productInfo.insDate = `${yyyy}-${mm}-${dd}`,
                '1', this.productPromotion.promoName, promoCode]}); 
            //console.log("errCheck:", Object.keys(resIns_pdtPromotion)[0]);
              // 인서트오류
            if(Object.keys(resIns_pdtPromotion)[0] === 'error') {
              this.$swal.fire('프로모션 저장오류', '', 'alert')
            }
            else{
              this.$swal.fire('', '프로모션 저장완료', 'success')
              location.reload();
            }
          }
        })
      }
      else if(p1==="U" && p2==="P") { //프로모션변경
        this.$swal.fire({
        icon: 'question',
        text: '프로모션 변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res_updateProductPromo = await this.$api("/api/updateProductPromo", 
              {param: [this.choicePromotion.promoStart, this.choicePromotion.promoEnd, this.cPromoFlag, this.choicePromotion.promoName, this.user.handlerId, 
                this.productId, this.seqPromo]});
            if(res_updateProductPromo.affectedRows > 0) {
              this.$swal.fire('프모모션적용 변경완료', '', 'success')
              location.reload();
            }
            else {
              this.$swal.fire('적용처리에러<br>현재정보확인 후 재실행 필요', '', 'error')
            }
          }
        })
      }
      else if(p1==="D" && p2==="A") { //기존 추가옵션 제거(not use)
        this.$swal.fire({
        icon: 'question',
        text: '추가옵션 제거?',
        showCancelButton: true,
        confirmButtonText: '제거',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res_deleteAddOptFlag = await this.$api("/api/deleteAddOptFlag", 
              {param: [this.user.handlerId,this.productId]});
            if(res_deleteAddOptFlag.affectedRows > 0) {
              let res_updAddOptFlag = await this.$api("/api/updateAddOptFlag", 
                {param: [this.user.handlerId,this.productId]});
                if(res_updAddOptFlag.affectedRows > 0) {
                  this.$swal.fire('추가옵션 제거완료', '', 'success')
                  location.reload();
                }
            }
            else {
              this.$swal.fire('추가옵션 제거에러<br>재실행 또는 관리자에게 문의하세요', '', 'error')
            }
          }
        })
      }
      else if(p1==="D" && p2==="E") { //기존 기타옵션 제거(not use)
        this.$swal.fire({
        icon: 'question',
        text: '선택옵션 제거?',
        showCancelButton: true,
        confirmButtonText: '제거',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res_deleteEtcOptFlag = await this.$api("/api/deleteEtcOptFlag", 
              {param: [this.user.handlerId,this.productId]});
            if(res_deleteEtcOptFlag.affectedRows > 0) {
              let res_updEtcOptFlag = await this.$api("/api/updateEtcOptFlag", 
                {param: [this.user.handlerId,this.productId]});
                if(res_updEtcOptFlag.affectedRows > 0) {
                  this.$swal.fire('기타옵션 제거완료', '', 'success')
                  location.reload();
                }
            }
            else {
              this.$swal.fire('기타옵션 제거에러<br>재실행 또는 관리자에게 문의하세요', '', 'error')
            }
          }
        })
      }
      else if(p1==="U" && p2==="Q") { //상품관리수량 변경
        this.$swal.fire({
        icon: 'question',
        text: '상품관리수량 변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res_updateSalesVolume = await this.$api("/api/updateSalesVolume", 
              {param: [this.salesVolumeInfo.pdtQty,this.salesVolumeInfo.safetyFigure,this.user.handlerId,this.productId]});
            if(res_updateSalesVolume.affectedRows > 0) {
              this.$swal.fire('상품수량 변경완료', '', 'success')
              location.reload();
            }
            else {
              this.$swal.fire('적용처리에러<br>현재정보확인 후 재실행 필요', '', 'error')
            }
          }
        })
      }
      else if(p1==="E" && p2==="O") { //기타옵션
        this.$swal.fire({
        icon: 'question',
        text: '선택옵션 생성?',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.InsertEtcOption();
          }
        })
      }
      else if(p1==="C" && p2==="O") { //기타옵션 필수선택정도
        this.$swal.fire({
        icon: 'question',
        text: '선택옵션 선택수준 변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let i = 0;
            while(i < this.etcOptionInfo.length) {
            let res_etcOptFlagUpdate = await this.$api("/api/etcOptFlagUpdate", 
              {param: [this.etcOptionInfo[i].reqOptFlag,this.user.handlerId,this.productId,this.etcOptionInfo[i].etcOptName]});
              i++;
              if(res_etcOptFlagUpdate.affectedRows < 0 || res_etcOptFlagUpdate.affectedRows === undefined){
                alert("변경작업 중 에러발생\n관리자에게 문의해주세요.");
                return false;
              }
            }
            this.$swal.fire('옵션선택수준 변경완료', '', 'success')
            location.reload();
          }
        })
      }
      else {
        return this.$swal.fire("","Case-DBTransation: <br>오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      }
    },

    //선택옵션 처리공통함수
    async InsertEtcOption(){
      for(let j=0; j < this.etcOptions.length; j++)
      {
        let resIns_etcOption = await this.$api("/api/etcOptInsert", {param: [
          this.productInfo.pdtCode, this.etcOptions[j].etcOptName, this.etcOptions[j].etcOptPrice, 'Y', this.user.handlerId, this.etcOptions[j].reqOptFlag, 
          this.etcOptions[j].etcChild, this.etcOptions[j].etcInf, this.etcOptions[j].etcDis,
          this.etcOptions[j].etcOptCode
        ]});
          //console.log(resIns_etcOption);
          // 인서트오류처리
        if(Object.keys(resIns_etcOption)[0] === 'error') {
          alert("기타옵션 저장오류");
        }
      }
      let res_UpdPdtEtcOptFlag = await this.$api("/api/changePdtEtcOptFlag", {param: [this.productId]});
      if(res_UpdPdtEtcOptFlag.affectedRows < 0 || res_UpdPdtEtcOptFlag.affectedRows === undefined){
        this.$swal.fire('','상품리스트에서 기타옵션 적용표시(Y)확인필요<br>미표시이면 관리자에게 문의하세요', 'warning')
      }
      else{
        this.$swal.fire('','기타옵션 생성완료', 'success')
        location.reload();
      }
    },

    //선택옵션코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_etcOptCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","옵션코드 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    }    
  }
}
</script>

<style>
  .flex-pdtCode {
    display: flex;
    padding-left: 10px;
    gap: 20px; /* 요소 간의 간격 조절 */
  }

  .flex-input {
    display: flex;
  }

  .icon {
    font-size: 1.25rem; /* 아이콘 크기 조절 (fs-5에 맞추기) */
    margin-right: 0.5rem; /* 아이콘과 텍스트 간의 간격 조절 */
    vertical-align: middle; /* 아이콘을 텍스트 중간 높이에 맞추기 */
  }  
</style>