<template>
  <div ref="tableContainer" id="repFrame" class="table-container">

    <div class="text-end print-hide">
      <button type="button" class="btn btn-outline-success btn-sm m-1" style="font-size: small; padding-right: 10px;" @click="printScreen(this.reqCode)" v-if="expenseInfo.reqStat != 'lv0'">
        Print <i class="bi bi-printer"></i>
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm m-1" style="font-size: smaller;" @click="reqDest(this.reqCode)" v-if="checkerInfo.handlerGrade <=5 && expenseInfo.reqStat != 'lv0'">
        품의파기 <i class="bi bi-trash3"></i>
      </button>
    </div>

    <div class="row col-12" style="padding-left:50%;">
      <table class="table table-bordered border-secondary w-100 h-auto text-center fs-6">
        <tr>
          <td rowspan="2" style="width:10%; height: 90px; writing-mode: vertical-lr;background-color:lightgray; vertical-align: middle;"><center>결&nbsp;&nbsp;&nbsp;재</center></td>
          <td style="width:30%; height: 20px;">담&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;당</td>
          <td style="width:30%; height: 20px;">팀&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;장</td>
          <td style="width:30%; height: 20px;">대&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;표</td>
        </tr>
        <tr v-if="expenseInfo.reqStat != 'lv0'">
          <td style="height: 70px;">
            {{ expenseInfo.checker1Name }}
          </td>
          <td style="height: 70px;">
            <button type="button" class="btn btn-outline-primary btn-md" v-if="user.handlerGrade <= 3 && showAccLv5
            ==='Y'"   @click="makeExpReport('lv5')">결재</button>&nbsp;&nbsp;
            <button type="button" class="btn btn-outline-danger btn-md" v-if="user.handlerGrade <= 3 && showRejLv5 === 'Y'"   @click="reject('rj5')">반려</button>            
            <span v-else-if="expenseInfo.reqStat === 'lv9' || expenseInfo.reqStat === 'lv5' || expenseInfo.reqStat === 'rj9' || expenseInfo.reqStat === 'rj5' || expenseInfo.reqStat === 'lv0'">
              <b v-if="expenseInfo.reqStat === 'rj5'" class="text-danger">(반려) </b>{{ expenseInfo.checker5Name}}
            </span>
          </td>
          <td style="height: 70px;">
            <button type="button" class="btn btn-outline-primary btn-md" v-if="user.handlerGrade <= 2 && showAccLv9
            ==='Y'" @click="makeExpReport('lv9')">결재</button>&nbsp;&nbsp;
            <button type="button" class="btn btn-outline-danger btn-md" v-if="user.handlerGrade <= 2 && showRejLv9
            ==='Y'" @click="reject('rj9')">반려</button>            
            <span v-else-if="expenseInfo.reqStat === 'lv9' || expenseInfo.reqStat === 'rj9' || expenseInfo.reqStat === 'lv0'">
              <b v-if="expenseInfo.reqStat === 'rj9'" class="text-danger">(반려) </b>{{ expenseInfo.checker9Name}}
            </span>
          </td>
        </tr>
      </table>
    </div>

    <table class="table table-bordered border-secondary w-100 h-auto text-center">
      <tr>
        <td colspan="7">
          <b class="fs-3" v-if="expenseInfo.reqStat === 'lv0'"><s>지&nbsp;출&nbsp;결&nbsp;의&nbsp;서</s>(파기)</b>
          <b class="fs-3" v-else>지&nbsp;출&nbsp;결&nbsp;&nbsp;의&nbsp;서</b>
          <span>({{ reqCode }})</span>
          <p style="text-align: end; padding-right: 40px;">
            <!-- <b class="fs-5">엔타비</b> -->
          </p>

          <table class="w-100 h-auto mt-0 mb-3">
            <tr>
              <td style="border: 0;width: 70%; padding-left: 10px;">
                <div class="mt-0 mb-0 text-start fs-5" :key="i" v-for="(ex,i) in arr_expenseByCurrency">
                  일금 <b>{{ getNumberToKorean(ex.tot_dPrice) }}</b>
                  <span class="fs-6 text-danger">( {{ ex.curName }}) </span>
                </div>
              </td>
              <td style="border: 0;width:30%; padding-right: 10px;">
                <div class="mt-0 mb-0 text-end fs-5" :key="i" v-for="(ex,i) in arr_expenseByCurrency">
                  <span class="text-danger">{{ ex.curSimbol }}</span> <b>{{ getCurrencyFormat(ex.tot_dPrice) }}</b>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:15%;">담당발의</td>
        <td style="vertical-align: middle; width:10%;">{{ getDateFormat(expenseInfo.check1Date) }}</td>
        <td style="vertical-align: middle; width:5%;"> {{getTimeFormat(expenseInfo.check1Date) }}</td>
        <td style="vertical-align: middle; width:20%;">{{ expenseInfo.checker1Name }}</td>
        <td style="background-color:lightgray; vertical-align: middle; width:10%;">거래처</td>
        <td style="vertical-align: middle; width:25%;" class="text-primary fw-bold">
          {{ expenseInfo.pName }}
          <span style="font-size: small; color:grey;">({{expenseInfo.ptCode}})</span>
        </td>
      </tr>


      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:10%;" v-if="expenseInfo.reqStat==='rj5'">팀장반려</td>
        <td style="background-color:lightgray; vertical-align: middle; width:10%;" v-else>팀장결재</td>

        <td style="vertical-align: middle; width:15%;" v-if="expenseInfo.reject5Date != undefined">
          {{ getDateFormat(expenseInfo.reject5Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else-if="expenseInfo.check5Date != undefined">
          {{ getDateFormat(expenseInfo.check5Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else></td>

        <td style="vertical-align: middle; width:15%;" v-if="expenseInfo.reject5Date != undefined">
          {{ getTimeFormat(expenseInfo.reject5Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else-if="expenseInfo.check5Date != undefined">
          {{ getTimeFormat(expenseInfo.check5Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else></td>

        <td style="vertical-align: middle; width:15%;">
          {{ expenseInfo.checker5Name }}
        </td>

        <td style="background-color:lightgray; vertical-align: middle; width:10%;" colspan="2">계정과목</td>
      </tr>

      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:10%;" v-if="expenseInfo.reqStat==='rj9'">대표반려</td>
        <td style="background-color:lightgray; vertical-align: middle; width:10%;" v-else>대표결재</td>

        <td style="vertical-align: middle; width:15%;" v-if="expenseInfo.reject9Date != undefined">
          {{ getDateFormat(expenseInfo.reject9Date) }}
        </td>        
        <td style="vertical-align: middle; width:15%;" v-else-if="expenseInfo.check9Date != undefined">
          {{ getDateFormat(expenseInfo.check9Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else></td>

        <td style="vertical-align: middle; width:15%;" v-if="expenseInfo.reject9Date != undefined">
          {{ getTimeFormat(expenseInfo.reject9Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else-if="expenseInfo.check9Date != undefined">
          {{ getTimeFormat(expenseInfo.check9Date) }}
        </td>
        <td style="vertical-align: middle; width:15%;" v-else></td>

        <td style="vertical-align: middle; width:15%;">
          {{ expenseInfo.checker9Name }}
        </td>

        <td style="vertical-align: middle; width:15%;" colspan="2" rowspan="2">
          <input type="text" class="form-control from-control-sm" style="font-size: small;" placeholder="필요시 계정과목을 입력하세요" v-model="expenseInfo.accountSubject">
        </td>
      </tr>


      <tr class="fs-6">
        <td style="background-color:lightgray; vertical-align: middle; width:10%;">지출처리</td>

        <!-- 지출일자 -->
        <td style="vertical-align:center; width:15%;">
          <div style="text-align:end; vertical-align:middle;padding:0%;" v-if="checkerInfo.handlerGrade <= 2 && (expenseInfo.payerId == '' || expenseInfo.payerId == undefined)">
            <input type="date" class="form-control form-control-sm" ref="searchStart" v-model="this.payDate" style="font-size:smaller">
            <!-- <button type="button" class="btn btn-outline-danger btn-sm" style="font-size:smaller;" @click="pay(reqCode)" v-if="expenseInfo.reqStat != 'lv0'">지출</button> -->
          </div>
          <div style="text-align:center; vertical-align:middle;padding:0%;" v-else-if="expenseInfo.payerId != undefined">
            {{ getDateFormat(expenseInfo.payDate) }}
          </div>
        </td>

        <!-- 지출시간 -->
        <td style="vertical-align:center; width:15%;">
          <div style="text-align:end; vertical-align:middle;padding:0%;" v-if="checkerInfo.handlerGrade <= 2 && (expenseInfo.payerId == '' || expenseInfo.payerId == undefined)">
            <input type="time" class="form-control form-control-sm" ref="searchStart" v-model="this.payTime" style="font-size:smaller">
            <!-- <button type="button" class="btn btn-outline-danger btn-sm" style="font-size:smaller;" @click="pay(reqCode)" v-if="expenseInfo.reqStat != 'lv0'">지출</button> -->
          </div>
          <div style="text-align:center; vertical-align:middle;padding:0%;" v-else-if="expenseInfo.payerId != undefined">
            {{ getTimeFormat(expenseInfo.payDate) }}
          </div>
        </td>

        <!-- 지출자 -->
        <td style="vertical-align:center; width:15%;">
          <div style="text-align:end; vertical-align:middle;padding:0%;" v-if="checkerInfo.handlerGrade <= 2 && (expenseInfo.payerId == '' || expenseInfo.payerId == undefined)">           
            <button type="button" class="btn btn-outline-danger btn-sm" style="font-size:smaller;" @click="pay(reqCode)" v-if="expenseInfo.reqStat != 'lv0'">지출</button>
          </div>
          <div style="text-align:center; vertical-align:middle;padding:0%;" v-else-if="expenseInfo.payerId != undefined">
            {{ expenseInfo.payerName }}
          </div>
        </td>
      </tr>

      <tr>
        <td colspan="7"></td>
      </tr>

      <tr  class="border-secondary fs-5">
        <td class="text-center" id="subTitle" colspan="7"><b>내&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;역</b></td>
      </tr>
      <tr class="bgColor fs-6">
        <th colspan="1">적요</th>
        <th colspan="1">금액</th>
        <th colspan="1">환율</th>
        <th colspan="2">비고</th>
        <th colspan="1">증빙자료</th>
      </tr>
      <tr  class="border-secondary fs-6" :key="j" v-for="(e,j) in expenseInfo_detail">
        <!-- 적요:항목명 -->
        <td colspan="1">
          {{ e.summary }}
        </td>

        <!-- 금액 -->
        <td colspan="1" style="text-align: end; padding-right: 10px;">
          <span class="text-danger">{{ e.fRemarks }}</span>{{ this.getCurrencyFormat(e.reqPrice) }}
        </td>

        <!-- 환율 -->
        <td colspan="1" style="text-align: end; padding-right: 10px; color:darkgray;">
          {{ e.exRate }}
        </td>

        <!-- 비고: 비고사항 -->
        <td colspan="2" style="text-align: start; padding-left: 5px; font-size: smaller;">
          {{ e.reqRemarks }}
        </td>

        <!-- 증빙자료 -->
        <td style="font-size: smaller;text-align: start;">
          <div :key="j" v-for="(r,j) in e.recName" style="text-align: start;padding-top: 1px; padding-bottom: 1px;">
            <a href="javascript:void(0)" @click="downloadUrl(e.dealCode+'-'+r.rSubNo, r.recName)"><i class="bi bi-paperclip text-secondary"></i>{{ r.recName }}</a>
          </div>
        </td>
      </tr>

      <!-- 거래처계좌정보 -->
      <tr>
        <td colspan="6" class="fs-6" style="text-align: center;">
          <div class="input-group input-group-sm" style="width:60%;margin:0 auto;">
            <!-- <input type="text" class="form-control form-sm text-center" placeholder="은행명" v-model="expenseInfo.bankName"> -->
            <select class="form-select form-select-sm text-center" v-model="expenseInfo.bankCode" style="font-size: smaller;" ref="finCd">
              <option :value="f.fCodeNo" :key="i" v-for="(f,i) in this.finBankingInfo">{{ f.fName }}</option>
            </select>              
            <input type="text" class="form-control form-sm text-center" placeholder="계좌번호" v-model="expenseInfo.bankAccount">
            <input type="text" class="form-control form-sm text-center" placeholder="예금주" v-model="expenseInfo.accHolder">
            <!-- <button class="btn btn-primary">변경</button> -->
          </div>
        </td>
      </tr>

      <tr class="fs-6">
        <td colspan="2">합&nbsp;&nbsp;&nbsp;계</td>
        <td colspan="4" class="fs-5 text-end text-danger fw-bold" style="padding-right: 20px;">₩{{ getCurrencyFormat(sumOfExpPrice) }}</td>
      </tr>

      <tr class="fs-6">
        <td colspan="6">
          <div>
            위 금액을 영수(청구)합니다.
          </div>
          <div>
            {{ today }}
          </div>
        </td>
      </tr>
    </table>
  </div>

</template>

<script>
//import * as XLSX from "xlsx"
import moment from 'moment';
import {genCode} from '../assets/js/genCode'

export default {
  data() {
    return {
      expenseInfo: [],                //지출품의정보
      expenseInfo_detail: [],         //지품서 디테일
      expenseInfo_receipt: [],        //지품서 증빙파일
      obj_receipt: {},                //지품서 증빙파일객체

      sumOfExpPrice:[],               //통화별지출총액
      arr_expenseByCurrency:[],       //통화별지출정보
      checkerInfo: [],                //처리자인적정보

      finBankingInfo: [],             //은행정보

      showAccLv5: "N",                //팀장결재버튼표시
      showRejLv5: "N",                //팀장반려버튼표시
      showAccLv9: "N",                //대표결재버튼표시
      showRejLv9: "N",                //대표반려버튼표시

      // reqCode: "",                //지출품의코드(RE-)
      // dealCode: "",               //딜코드
      // pBankCode: "",              //거래처은행코드
      // pBankName: "",              //거래처은행명
      // pBankAccount: "",           //거래처계좌번호
      // pAccHolder: "",             //예금주
      // partnerName: "",            //거래처명
      // ptCode: "",                 //파트너코드
      // exRate: 0.00,               //환율
      // accountSubject: "",         //계정과목

      // check1Date: "",             //발의일
      // checker1Id: "",             //발의자
      // check5Date: "",             //팀장처리일
      // checker5Id: "",             //탐장
      // check9Date: "",             //대표처리일
      // checker9Id: "",             //대표

      // payDate: "",                //지출일
      // payerId: "",                //지출자
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.reqCode = this.$route.query.rCode;
    //this.dealCode = this.$route.query.dCode;

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    this.payDate = today1.format("YYYY-MM-DD")
    this.payTime = moment().format("HH:mm:ss");
    console.log("today:", this.today, "todayTime:", this.todayTime)

    this.getHandlerInfo()
    this.getExpenseInfo(this.reqCode)
    this.getFinBankingInfo()
  },
  mounted() {
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
    document.getElementById("top").style.display = "none";
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getTimeFormat(date)
    {
      return this.$timeFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },
    //숫자를 한글로 변환
    getNumberToKorean(value){
      return this.$numberToKorean(value)
    },

    //은행정보
    async getFinBankingInfo(){
      let finBankingInfo = await this.$api("/api/finBankingInfo", {param: [
        'Inst', 'Bank', 'Y'
      ]})

      if(finBankingInfo.length > 0)
      {
        this.finBankingInfo = finBankingInfo
      }
    },

    async getHandlerInfo(){
      //처리자인적정보
      let checkerInfo = await this.$api("/api/handlerInfoById", {param: [this.user.handlerId]})
      if(checkerInfo.length > 0)
      {
        this.checkerInfo = checkerInfo[0]
      }
      console.log("checkerInfo:", this.checkerInfo)
    },

    //반려확인
    reject(checker){                                    //rj5 or rj9
      this.$swal.fire({
        html: "당해 품의를 반려하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "반려",
        cancelButtonText: "아니오"
      }).then(async (result)=>{
        if(result.isConfirmed){
          await this.rejectOK(checker)
        }
      })
    },

    //반려진행
    async rejectOK(c){
      //alert(c)
      const { value: rejectComment } = await this.$swal.fire({
        input: "textarea",
        inputLabel: "반려사유",
        inputPlaceholder: "반려사유를 입력해주세요(300자 이내)",
        inputAttributes: {
          "aria-label": "반려사유"
        },
        showCancelButton: true
      });
      if (rejectComment) {
        this.$swal.fire(rejectComment);
      }

      //반려처리
      let rUseFlag = "Y"
      let res_rejectRequisition = await this.$api("/api/proc_rejectRequisition", {param:[
        c, this.expenseInfo.reqCode, this.expenseInfo.dealCode, rUseFlag, this.user.handlerId, rejectComment
      ]});

      if(res_rejectRequisition.affectedRows < 0)
      {
        this.$swal.fire("","반려처리 중 오류가 발생했습니다.", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","반려처리되었습니다", "warning")
        location.reload(true)
      }
    },

    //결재 반려버튼 표시처리
    showButton(){
      let reqStat = this.expenseInfo.reqStat
      console.log("reqStat:", reqStat)
      
      switch(reqStat) {
        case "lv1": //본인발의상태
          this.showAccLv5 = "Y"
          this.showRejLv5 = "Y"
          this.showAccLv9 = "Y"
          this.showRejLv9 = "Y"
          break;

        case "lv5": //팀장결재
          this.showAccLv5 = "N"
          this.showRejLv5 = "N"
          this.showAccLv9 = "Y"
          this.showRejLv9 = "Y"
          break;
          
        case "lv9": //대표결재
          this.showAccLv5 = "N"
          this.showRejLv5 = "N"
          this.showAccLv9 = "N"
          this.showRejLv9 = "N"
          break;

        case "rj5": //팀장반려
          this.showAccLv5 = "N"
          this.showRejLv5 = "N"
          this.showAccLv9 = "N"
          this.showRejLv9 = "N"
          break;
          
        case "rj9": //대표반려
          this.showAccLv5 = "N"
          this.showRejLv5 = "Y"
          this.showAccLv9 = "N"
          this.showRejLv9 = "N"
          break;

        default:
          this.showAccLv5 = "Y"
          this.showRejLv5 = "Y"
          this.showAccLv9 = "Y"
          this.showRejLv9 = "Y"
      }

    },

    //지출정보 처리
    async getExpenseInfo(rCode){
      //지품서 마스터
      let expenseInfo = await this.$api("/api/expReportInfo", { param: [rCode] });
      if(expenseInfo.length > 0) {
        this.expenseInfo = expenseInfo[0]
      }
      console.log("expenseInfo:>>>>>>>>>>>>>>>", this.expenseInfo)

      //지품서 디테일
      let expenseInfo_detail = await this.$api("/api/expReportInfo_detail", { param: [rCode] });
      if(expenseInfo_detail.length > 0) {
        this.expenseInfo_detail = expenseInfo_detail

        //지출금액 합산처리
        let i = 0
        let sumOfExpPrice = 0            //통화별 품의액합산
        let expPrice = 0                 //통화별 품의액
        while(i < this.expenseInfo_detail.length)
        {
          if(this.expenseInfo_detail[i].curCode === "KRW")
          {
            expPrice = this.expenseInfo_detail[i].reqPrice
          }
          else
          {
            expPrice = this.expenseInfo_detail[i].reqPrice * this.expenseInfo_detail[i].exRate
          }

          sumOfExpPrice += expPrice
          console.log("sumOfExpPrice:", sumOfExpPrice)
          i++
        }
        this.sumOfExpPrice = sumOfExpPrice
      }
      //통화별 지출액분류
      //curCode(통화코드), reqPrice(품의액), fName(통화명), fRemarks(통화심볼), exRate(환율)
      let arr_expenseByCurrency = this.expenseInfo_detail.reduce((acc, {curCode, reqPrice, fName, fRemarks, exRate}) => {
        if(acc[curCode])
        {
          acc[curCode].tot_dPrice += reqPrice
        }
        else
        {
          acc[curCode] = {
            tot_dPrice: reqPrice, curName: fName, curSimbol: fRemarks, dExRate: exRate
          }
        }
        return acc
      }, {})
      this.arr_expenseByCurrency = arr_expenseByCurrency

      // 증빙자료파일
      let expenseInfo_receipt = await this.$api("/api/expReport_receipt", { param: [rCode] });

      //let obj_receipt = {}
      if(expenseInfo_receipt.length > 0)
      {
        let obj_receipt = expenseInfo_receipt.reduce((obj, item) => {
          // 기존에 해당 키(reqSubNo)가 존재한다면 기존 배열에 추가, 없다면 새 배열 생성
          obj[item.reqSubNo] = obj[item.reqSubNo] || [];
          obj[item.reqSubNo].push({
            recName: item.recName,
            rSubNo: item.rSubNo
          });
          return obj;
        }, {});
        this.obj_receipt = obj_receipt
        //console.log("obj_receipt:::::::::::::", this.obj_receipt)
      }

      let j = 0
      while(j < expenseInfo_detail.length)
      {
        this.expenseInfo_detail[j].recName = this.obj_receipt[j]
        j++
      }
      //console.log("expenseInfo_detail::::::::::", this.expenseInfo_detail)
      this.showButton()
    },

    //증빙파일다운로드
    downloadUrl(folder, fileName) {
      console.log(folder, fileName)
      let encodedFolder = encodeURIComponent(folder);
      let encodedFilename = encodeURIComponent(fileName);

      //back end와 링크주소 일치해야 함
      //let url = `http://localhost:5000/filedownload/${encodedFolder}/${encodedFilename}`;
      let url = `https://ntabi.co.kr/filedownload/${encodedFolder}/${encodedFilename}`;
      //console.log("url:", url)

      window.location.href = url;
    },

    async makeExpReport(grade){
      //처리자 레벨
      let checkerLv = grade

      let reqCode = ""
      //지출품의서 생성 or 업데이트
      let checkerId = this.user.handlerId
      let payerId = ""
      let updId = ""
      let delId = ""
      let reqStat = ""
      let ttlDate = (this.expenseInfo.ttlDate == null || this.expenseInfo.ttlDate == undefined) ? null : this.getDateFormat(this.expenseInfo.ttlDate)

      if(grade === "lv1")
      {
        //지출품의 코드생성
        let catCode = "RE-"
        reqCode = await this.genCode(catCode)
        this.reqCode = reqCode
      }
      else if(grade === "lv5")
      {
        updId = this.user.handlerId
        reqStat = grade
      }
      else if(grade === "lv9")
      {
        updId = this.user.handlerId
        reqStat = grade
      }
      else
      {
        this.$swal.fire("","처리권한이 불명확합니다. 재시도해주세요","warning")
        return false
      }

      let rUseFlag = "Y"

      let res_createRequisition_m = await this.$api("/api/proc_createRequisition_m", {param:
        [
          this.reqCode, this.expenseInfo.dealCode, rUseFlag, checkerLv, checkerId, payerId, this.expenseInfo.ptCode, this.expenseInfo.bankCode, this.expenseInfo.bankAccount, this.expenseInfo.accHolder,
          this.expenseInfo.accountSubject, this.user.handlerId, updId, delId, reqStat, ttlDate
      ]});

      if(res_createRequisition_m.affectedRows < 0)
      {
        this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>지출결의서 처리 중 문제가 발생했습니다.('+ res_createRequisition_m.error.sqlMessage +')', 'warning')
        return false;
      }
      else
      {
        let promises = []

        if(checkerLv === "lv5" || checkerLv === "lv9")
        {
          let i = 0
          while(i < this.expenseInfo_detail.length)
          {
            let res_createRequisition_d = await this.$api("/api/updateDealHistory", {param:
              [ checkerLv, this.reqCode, this.expenseInfo_detail[i].dealCode, this.expenseInfo_detail[i].recName[0].rSubNo]
            });
            promises.push(res_createRequisition_d)
            i++
          }
        }

        Promise.all(promises).then(() => {
          this.$swal.fire("", "지출품의가 진행됩니다", "info"). then(() => {
          location.reload(true)
          })
        })

      }
    },

    async pay(rCode){
      let payDate = this.payDate + " " + this.payTime
      if(!this.payDate || !this.payTime){
        this.$swal.fire("", "지출일자와 지출시간을 지정하여 주십시오.", "warning")
        return false
      }
      if(this.expenseInfo.reqStat != "lv9")
      {
        this.$swal.fire("", "대표이사의 결재가 확인되지 않습니다.", "question")
        return false
      }
      else
      {
        let payRequisition = []
        if(!this.payDate && !this.payTime)
        {
          payRequisition = await this.$api("/api/payRequisition", {param: [
            this.user.handlerId, this.expenseInfo.accountSubject, rCode
          ]})
        }
        else
        {
          //let payDate = this.payDate + " " + this.payTime
          payRequisition = await this.$api("/api/payRequisition_new", {param: [
            this.user.handlerId, payDate, this.expenseInfo.accountSubject, rCode
          ]})
        }
        if(payRequisition.affectedRows < 0)
        {
          this.$swal.fire("", "지출처리 중 오류가 발생했습니다. 재시도를 해주시고 오류가 반복되면 관리자에게 문의해주십시오.", "warning")
          location.reload(true)
        }
        else
        {
          let updExpDateForRequisition = await this.$api("/api/updExpDateForRequisition", {param: [
            payDate, this.expenseInfo.dealCode, rCode
          ]})

          if(updExpDateForRequisition.affectedRows < 0)
          {
            this.$swal.fire("", "지출처리 중 오류가 발생했습니다. 재시도를 해주시고 오류가 반복되면 관리자에게 문의해주십시오.", "warning")
          }
          else
          {
            this.$swal.fire("", "지출내역이 저장되었습니다", "success")
            location.reload(true)
          }
        }
      }
    },

    //지품코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++)
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N")
      {
        this.$swal.fire("","예약번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      }
      else
      {
        return rtnCode
      }
    },


    // 지정영역 화면인쇄
    async printScreen(rCode)
    {
      //품의서 인쇄기록(최종)
      await this.$api("/api/printRequisition", {param: [
        this.user.handlerId, rCode
      ]})

      //화면전체
      window.print()
    },

    //품의파기
    reqDest(rCode){
      let reqStat = "lv0"   //파기상태코드

      this.$swal.fire({
          html: '당해 품의서를 파기하시겠습니까?<br>파기된 품의서는 복구하기 어렵습니다.',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오'
        }).then(async(result) => {
          if(result.isConfirmed)
          {
            let destRequisition = await this.$api("/api/destRequistion", {param: [
              this.user.handlerId, reqStat, rCode
            ]})

            if(destRequisition.affectedRows < 0)
            {
              this.$swal.fire("","품의파기 처리 중 오류가 발생했습니다.", "warning")
              location.reload(true)
            }
            else
            {
              let promises = []

              let i = 0
              while(i < this.expenseInfo_detail.length)
              {
                let res_createRequisition_d = await this.$api("/api/updateDealHistory", {param:
                  [ reqStat, this.reqCode, this.expenseInfo_detail[i].dealCode, this.expenseInfo_detail[i].recName[0].rSubNo]
                });
                promises.push(res_createRequisition_d)
                i++
              }

              Promise.all(promises).then(() => {
                this.$swal.fire("", "당해품의서는 파기되었습니다", "info"). then(() => {
                location.reload(true)
                })
              })
            }
          }
        }
      )
    },



    //화면인쇄(정산서 영역)
    async printScreen_excel(){
      //인쇄화면에서 버튼들 삭제
      // Get the table container
      const tableContainer = this.$refs.tableContainer;

      // Clone the table container to avoid altering the original content
      const clonedTableContainer = tableContainer.cloneNode(true);

      // Remove the buttons from the cloned table container
      const buttons = clonedTableContainer.querySelectorAll('button');
      buttons.forEach(button => button.remove());

      // Get the HTML content of the cloned table container without buttons
      const printContent = clonedTableContainer.innerHTML;

      // Define styles for the table border and background color
      const printStyles = `
          <style>
            table {
              width: 100%;
              border: 1px solid black;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;-
            }
            td[style*="background-color:lightgray"] {
              background-color: lightgray !important;
            }
          </style>`;

      // Print the content with styles
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write('<html><head><title>Print</title>' + printStyles + '</head><body>' + printContent + '</body></html>');
      printWindow.document.close();
      printWindow.print();

      //지정영역 인쇄
      // const printContent = this.$refs.tableContainer.innerHTML;
      // const printWindow = window.open('', '_blank');
      // printWindow.document.open();
      // printWindow.document.write('<html><head><title>Print</title></head><body>' + printContent + '</body></html>');
      // printWindow.document.close();
      // printWindow.print();

      //화면전체
      //window.print()
    },

    goBack(){
      //history.back(-1)
      //this.$router.go(-1);
      this.$router.push({path:'/businessInfo', query: {bFlag: 'B'}})
    },

    // //엑셀다운로드
    // exportToExcel() {
    //   // Get the table element
    //   let tableContainer = this.$refs.tableContainer;
    //   let tables = tableContainer.querySelectorAll('table');

    //   // Transfer many tables to one table
    //   const tempTable = document.createElement('table');
    //   tables.forEach((table) => {
    //     const rows = table.querySelectorAll('tr');
    //     rows.forEach((row) => {
    //       tempTable.appendChild(row.cloneNode(true));
    //     });
    //   });

    //   // Convert tempTable to workbook
    //   const workbook = XLSX.utils.table_to_book(tempTable);

    //   // Get the first worksheet (assuming only one)
    //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    //   // Function to convert RGB color to Hex
    //   function rgbToHex(rgb) {
    //     const rgbValues = rgb.match(/\d+/g);
    //     const hexColor = rgbValues
    //       ? '#' + rgbValues.map((x) => parseInt(x).toString(16).padStart(2, '0')).join('')
    //       : rgb;
    //     return hexColor.replace('#', ''); // Hex format without #
    //   }

    //   // Iterate through the rows of the tempTable
    //   tempTable.querySelectorAll('tr').forEach((row, rowIndex) => {
    //     row.querySelectorAll('td').forEach((cell, colIndex) => {
    //       // Get the background color of the HTML cell
    //       const bgColor = window.getComputedStyle(cell).backgroundColor;

    //       // Convert the color to a format that can be used in Excel
    //       const excelColor = rgbToHex(bgColor);

    //       // Get the corresponding Excel cell reference
    //       const cellRef = XLSX.utils.encode_cell({c: colIndex, r: rowIndex});
    //       const excelCell = worksheet[cellRef];

    //       if (excelCell) { // Check if Excel cell is defined
    //         // Set the background color in Excel
    //         excelCell.s = {
    //           ...excelCell.s, // Keep existing styles
    //           fill: {
    //             fgColor: { rgb: excelColor }
    //           },
    //           font: {
    //             sz: 14 // Font size in points
    //           },
    //           border: {
    //             top: {style: "thin"},
    //             bottom: {style: "thin"},
    //             left: {style: "thin"},
    //             right: {style: "thin"},
    //           }
    //         };
    //       }
    //     });
    //   });

    //   // Apply styles to header (assuming header is in the first row)
    //   const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    //   for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    //     const cellRef = XLSX.utils.encode_cell({c: col, r: headerRange.s.r});
    //     const cell = worksheet[cellRef];

    //     if (cell) {
    //       cell.s = {
    //         font: {
    //           bold: true,
    //           sz: 12, // Font size in points
    //         },
    //         border: {
    //           top: {style: "thin"},
    //           bottom: {style: "thin"},
    //           left: {style: "thin"},
    //           right: {style: "thin"},
    //         }
    //       };
    //     }
    //   }


    //   // Create Excel file
    //   const excelFile = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    //   // Create a Blob with the Excel file
    //   const blob = new Blob([this.s2ab(excelFile)], { type: 'application/octet-stream' });

    //   // Create a download link
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   //a.download = '정산서.xlsx'; // Filename
    //   a.download = this.dealCode +'.xlsx'; // Filename
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // },
    // s2ab(s) {
    //   const buf = new ArrayBuffer(s.length);
    //   const view = new Uint8Array(buf);
    //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    //   return buf;
    // },
  }
}
</script>

<style>
table{
  border-collapse: collapse;
}

th, td{
  padding: 5px;
}

/* .table_sup td {
  padding: 0
} */

.table-container {
  width: 80%;
  padding-left: 20%;
}

@media print {
  .table-container {
    width: 100%;
    padding-left: 0;
  }
}

/*인쇄화면에서 엑셀과 프린트 버튼은 보이지 않도록*/
@media print {
  .print-hide {
    display:none;
  }
  .bgColor {
    background-color: lightgray;
  }
}
</style>